import React from "react";
import styled, { css } from "styled-components";

export type MarginValue = string | number;
export type MarginValues = [MarginValue, MarginValue];

export interface IMarginerProps {
  margin: MarginValue | MarginValues;
  direction?: "horizontal" | "vertical";
}

const marginStyles = css<IMarginerProps>`
  ${({ direction, margin }) => {
    const [topMargin, bottomMargin] =
      typeof margin === "string" || typeof margin === "number"
        ? [margin, margin]
        : margin;

    if (direction === "vertical") {
      return css`
        margin-top: ${topMargin};
        margin-bottom: ${bottomMargin};
      `;
    } else {

      return css`
        margin-left: ${topMargin};
        margin-right: ${bottomMargin};
      `;
    }
  }}
`;

const MarginerContainer = styled.div<IMarginerProps>`
  ${marginStyles}
`;

function Marginer(props: IMarginerProps) {
  return <MarginerContainer {...props} />;
}

Marginer.defaultProps = {
  direction: "horizontal",
};

export { Marginer };
