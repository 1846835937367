import AuthPage from "./app/containers/AuthPage";
import HomePage from './app/containers/HomePage';
import Contact from './app/containers/Contact';
import { Footer } from './app/components/Footer';
import { FormDataProvider } from './app/containers/AddCarForm/formDataContext';
import TopCars from './app/containers/HomePage/topCars';
import TopBar from './app/components/TopBar';
// import AddCarForm from './app/containers/AddCarForm';
import SocialSidebar from './../src/app/components/SocialSidebar/index'; // Import the SocialSidebar

import AddCarForm from './app/containers/AddCarForm/MainForm';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, gql, useMutation, useQuery } from '@apollo/client';
import { Logo } from './app/components/logo';
import { CREATE_USER_MUTATION } from "./app/services/userService/queries";
import { apolloClient as client } from './app/graphql';
// In other components or App.tsx

import { GET_USER_QUERY_BY_GOOGLE_ID } from "./app/services/userService/queries";
import PrivacyPolicy from "./app/containers/privacypolicy";
import CarPage from "./app/containers/CarPage";
import ScrollToTop from "./app/components/ScrolltoTop";
import TermsandConditions from "./app/containers/Terms&Conditions";
import UserProfile from "./app/containers/UserProfile";
import SearchResults from "./app/containers/searchresults";
import './App.css'
import Chat from "./app/containers/Chat/Chat";
import ChatProvider from "./Context/ChatProvider";
import UpdateCarForm from "./app/containers/UpdateCar/Index";
// import CarsByCity from "./app/containers/Carsbycity";
import SearchResultsByBrandName from "./app/containers/SearchResultsByBrandName";
import SearchResultsByLocation from "./app/containers/SearchResultsByLocation";
// UserTypes.ts
import NotFoundPage from "./app/containers/404/404";
export interface User {
  id: string;
  googleId: string;
  displayName: string;
  email: string;
  cnic: string;
  contactNumber: string;
  dateOfBirth?: string;
  isVerified?: boolean;
  photoLink?: string;

}

export function App() {
  const [user, setUser] = useState<User | null>(null);
  const [createUser] = useMutation(CREATE_USER_MUTATION);
  const [systemUserId, setSystemUserId] = useState<string | null>(null); // Add this state


  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch("https://auth.hireacar.pk/auth/login/success", {
          method: "get",
          credentials: "include",
        });
        if (response.status === 200) {
          const resObject = await response.json();
          setUser(resObject.user);
          localStorage.setItem('user', JSON.stringify(resObject.user));
          console.log(resObject.user.id); // Check the value here
          const { data } = await client.query({
            query: GET_USER_QUERY_BY_GOOGLE_ID,
            variables: {
              id: resObject.user.id,
            },
          });
          const userByGoogleId = data.userByGoogleId;

          if (userByGoogleId === null) {
            // User not found, create a new user
            const newUser = {
              googleId: resObject.user.id,
              displayName: resObject.user.displayName,
              email: resObject.user.emails[0].value,
              photoLink: resObject.user.photos[0].value,
              // cnic: "", // Initialize with an empty string or default value
              // contactNumber: "", // Initialize with an empty string or default value
              // dateOfBirth: "", // Initialize with an empty string or default value
          
            };

            try {
              const { data } = await createUser({
                variables: {
                  newUserData: newUser,
                },
              });
              console.log(data);
              // Handle successful user creation
            } catch (error) {
              console.log(error);
              // Handle error during user creation
            }
          } else {
            // User found, handle accordingly
            console.log(userByGoogleId);
            console.log(userByGoogleId.id)
            setSystemUserId(userByGoogleId.id); // Save the system ID

            // You can perform any additional actions or set the user state as needed
          }
        } else {
          throw new Error("Authentication has failed!");
        }
      } catch (error) {
        console.error(error);
      }
    };

    getUser();
  }, []);


return (
  
  <BrowserRouter>
    <ScrollToTop />
    <ChatProvider>
    <TopBar user={user || undefined} />
        <SocialSidebar /> {/* This ensures the sidebar is visible on all pages */}
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/add-car-form"  element={user?.id ? <AddCarForm userId={user ? user.id : "1"} /> : <AuthPage />} />
        <Route path="/auth-page" element={user?.id ? <Navigate to="/" /> : <AuthPage />} />
        <Route path="/contact"element={<Contact />} />
        {/* <Route path="/contact" element={user?.id ?<Contact /> : <Navigate to="/auth-page" />} /> */}
        <Route path="/UserProfile" element={systemUserId ? <UserProfile userId={systemUserId} /> : <AuthPage />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsandConditions" element={<TermsandConditions />} />
        <Route path="/rent-a-car/:city/:carType/:name/:id" element={
    <CarPage
      userId={systemUserId}
    />} />
        <Route path="/CarUpdate/:carId" element={<UpdateCarForm user={systemUserId} />} />
        <Route path="/cars/brand/:brandName" element={<SearchResultsByBrandName />} />
        <Route path="/cars/location/:cityName" element={<SearchResultsByLocation />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/TopCars" element={<TopCars />} />
        <Route path="/search-results" element={<SearchResults />} />
        <Route path="/Chat" element={<Chat />} />
      </Routes>
      <Footer className={undefined} />
      <Toaster />
    </ChatProvider>
  </BrowserRouter>
);
}

export default App;
