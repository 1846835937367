import React from 'react';
import tw from 'twin.macro';

const Container = tw.div`

 
  flex 
  flex-col 
  items-start 
  justify-start 
  bg-gray-100
  bg-fixed
pl-10
pr-10
mb-10`;

const Heading = tw.h1`
  text-4xl 
  font-bold 
  text-gray-800 
  mb-0
  mt-24
`;

const Subheading = tw.h2`
  text-2xl 
  text-gray-600 
  mb-8
  mt-10
`;

const TermsandConditions = () => {
    return (
        <Container>
            <Heading>Terms & Conditions   </Heading>
            <Subheading>Last Updated: [1 June 2023]</Subheading>
            <p>



                <h1>Terms and Conditions</h1>
                <h2>1. Introduction</h2>

                <p>By accessing or using our car rental marketplace, you agree to comply with and be bound by the following terms and conditions (the "Terms"). If you do not agree to these Terms, you must not access or use our services.</p>

                <h2>2. Definitions</h2>

<p>"Platform" refers to our car rental marketplace where owners and renters can connect.
"Owner" refers to the individual listing a car for rent.
"Renter" refers to the individual renting a car from an Owner.
                    "Services" refers to any services provided through our Platform.</p>
                <h2>3. User Responsibilities</h2>

                <h3>3.1 Owners</h3>
<p>Verification: Owners must verify the identity of Renters by conducting thorough background checks, including driving history and criminal background checks. The Platform is not responsible for any verification process or failures thereof.
                    Liability for Damages: Owners are solely responsible for any damages to their vehicle and agree to not hold the Platform liable for any such damages.</p>
                <h3>3.2 Renters</h3>
Verification: Renters must provide true and accurate information for verification by Owners.
Liability for Damages: Renters are responsible for any damages to the rented vehicle and agree to compensate the Owner according to the agreement made between the parties.
                <h2>4. No Liability</h2>

                <p>The Platform is not liable for any damages, disputes, or other issues that may arise between Owners and Renters. The Platform acts solely as a facilitator and does not participate in any transactions or agreements between parties.</p>

                <h2>5. Dispute Resolution</h2>

                <p>Any disputes between Owners and Renters must be resolved directly between the parties. The Platform may, at its discretion, assist in mediation but is not obligated to do so.</p>

                <h2>6. Termination</h2>

                <p>We may terminate or suspend your access to the Platform at any time, without prior notice or liability, for conduct that we believe violates these Terms or is harmful to other users of the Platform, us, or third parties, or for any other reason.</p>

                <h2>7. Governing Law</h2>

                <p>These Terms shall be governed by and construed in accordance with the laws of Pakistan, without regard to its conflict of law principles.</p>

                <h2>8. Amendments</h2>

                <p>We reserve the right to amend these Terms at any time. The amended Terms will be effective immediately upon posting on the Platform.</p>

                <h2>9. Contact Information</h2>

                <p>For any questions regarding these Terms, please contact us at info@hireacar.pk.</p>

            </p>
        </Container>
    );
};

export default TermsandConditions;