import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import SearchArea from "../../containers/HomePage/searchArea";
import bgimage from "./../../../assets/carIMage.jpg";
import { useMediaQuery } from "react-responsive";
const TestCompContainer = styled.div`
  ${tw`mt-12 `}
  width:100%;
`;

const BGcover = styled.div`
  ${tw` h-full  object-cover  flex flex-col  justify-center items-center `}
  background-image: url(${bgimage});
  background-color: rgba(0, 0, 0, 0.5); 
  background-blend-mode: overlay;
  background-position: center;
  background-attachment: fixed;
  width:100%
  
`;



const StyledText = styled.h1`
  ${tw`text-2xl sm:text-2xl  md:text-3xl lg:text-4xl text-white`}
  font-family: poppins;
  text-align: center;
  line-height: 3.5rem;


  @media (max-width: 768px) {
    width: 600px; /* Adjust width for medium-sized screens */


  }
  @media (max-width: 430px) {
    width: 400px; /* Adjust width for small screens */
  line-height: 2rem;
  text-align : center ; 
  font-size :30px;

  }

  @media (max-width: 375px) {
    width: 350px; /* Adjust width for small screens */
  line-height: 2rem;

  }
`;

export const Testcomp = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
const isSmallMobile = useMediaQuery({ query: '(max-width: 430px)' })
  return (
    <TestCompContainer style={{height:isSmallMobile?"55vh":"80vh"}}>
      <BGcover>
      
      <StyledText > Pakistan's First <span style={{color:"#1F4590"}}>  Car Rental  </span> Marketplace  </StyledText>
          <SearchArea />
      </BGcover>
    </TestCompContainer>
  );
};

export default Testcomp;
