/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Vector = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`vector ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 142 44"
      width="142"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-2"
        d="M0.243156 43.9047V29.1047H2.82316V43.9047H0.243156ZM12.1032 43.9047V37.4947H4.68316V35.2047H12.1032V29.1047H14.6732V43.9047H12.1032Z"
        fill="#231F20"
      />
      <path className="path-2" d="M17.3832 43.8447V29.1047H19.9632V43.8447H17.3832Z" fill="#231F20" />
      <path
        className="path-2"
        d="M36.3432 43.8447L32.6132 39.7447H27.0932V37.4747H33.2332C34.1632 37.4747 34.8732 37.2247 35.3432 36.7147C35.8132 36.2047 36.0532 35.4747 36.0532 34.5147C36.0532 33.5547 35.8032 32.8447 35.3132 32.3747C34.8232 31.9047 34.1232 31.6747 33.2332 31.6747H25.2032V43.8447H22.6432V29.1047H33.2232C34.0632 29.1047 34.8132 29.2247 35.4732 29.4847C36.1332 29.7347 36.6932 30.0947 37.1532 30.5547C37.6132 31.0247 37.9632 31.5847 38.2032 32.2447C38.4432 32.9047 38.5632 33.6547 38.5632 34.4747C38.5632 35.7047 38.2932 36.7347 37.7632 37.5647C37.2332 38.3947 36.4832 38.9947 35.5232 39.3547L39.9632 43.8347H36.3132L36.3432 43.8447Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M41.3232 29.1047H54.4832V31.6847H43.8832V41.2747H54.5032V43.8547H41.3232V29.1047ZM45.7632 37.4347V35.2647H53.9332V37.4347H45.7632Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M78.0532 43.8447L75.8432 40.1347H68.8632L70.0832 38.0447H74.6032L71.3232 32.5247L64.6332 43.8347H61.6932L70.1732 29.7247C70.3232 29.4647 70.4932 29.2647 70.7032 29.1147C70.9032 28.9647 71.1532 28.8947 71.4332 28.8947C71.7132 28.8947 71.9532 28.9747 72.1532 29.1147C72.3531 29.2647 72.5232 29.4647 72.6732 29.7247L81.1732 43.8347H78.0732L78.0532 43.8447Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M94.4332 43.8447C93.4032 43.8447 92.4532 43.6547 91.5832 43.2647C90.7132 42.8847 89.9531 42.3547 89.3131 41.6847C88.6731 41.0147 88.1731 40.2247 87.8131 39.3047C87.4531 38.3847 87.2732 37.3947 87.2732 36.3247C87.2732 35.2547 87.4531 34.2547 87.8131 33.3647C88.1731 32.4747 88.6731 31.7147 89.3131 31.0847C89.9531 30.4547 90.7132 29.9647 91.5832 29.6247C92.4532 29.2847 93.4032 29.1047 94.4332 29.1047H102.423V31.6847H94.4332C93.7732 31.6847 93.1632 31.8047 92.6032 32.0347C92.0432 32.2747 91.5632 32.5947 91.1532 33.0147C90.7432 33.4347 90.4332 33.9347 90.2031 34.5147C89.9731 35.0947 89.8632 35.7347 89.8632 36.4447C89.8632 37.1547 89.9731 37.7747 90.2031 38.3747C90.4332 38.9647 90.7532 39.4747 91.1532 39.9047C91.5632 40.3347 92.0432 40.6647 92.6032 40.9047C93.1632 41.1447 93.7732 41.2747 94.4332 41.2747H102.423V43.8547H94.4332V43.8447Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M119.883 43.8447L117.673 40.1347H110.693L111.913 38.0447H116.433L113.153 32.5247L106.463 43.8347H103.523L112.003 29.7247C112.153 29.4647 112.323 29.2647 112.533 29.1147C112.733 28.9647 112.983 28.8947 113.263 28.8947C113.543 28.8947 113.783 28.9747 113.983 29.1147C114.183 29.2647 114.353 29.4647 114.503 29.7247L123.003 43.8347H119.903L119.883 43.8447Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M138.253 43.8447L134.523 39.7447H129.003V37.4747H135.143C136.073 37.4747 136.783 37.2247 137.253 36.7147C137.723 36.2047 137.963 35.4747 137.963 34.5147C137.963 33.5547 137.713 32.8447 137.223 32.3747C136.733 31.9047 136.033 31.6747 135.143 31.6747H127.113V43.8447H124.553V29.1047H135.133C135.973 29.1047 136.723 29.2247 137.383 29.4847C138.043 29.7347 138.603 30.0947 139.063 30.5547C139.523 31.0247 139.873 31.5847 140.113 32.2447C140.353 32.9047 140.473 33.6547 140.473 34.4747C140.473 35.7047 140.203 36.7347 139.673 37.5647C139.143 38.3947 138.393 38.9947 137.433 39.3547L141.873 43.8347H138.223L138.253 43.8447Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M49.9932 4.62473C51.3132 12.0847 61.4332 15.4547 74.4532 16.9547C99.7032 19.8447 135.903 15.6947 140.223 20.6747C135.903 14.7647 120.593 13.5847 103.713 13.0847H103.643C80.8932 12.3947 55.3132 12.9047 49.9932 4.63473V4.62473Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M0.0931549 11.9047C22.0932 17.4947 41.1932 0.604729 74.2432 3.36473C82.5432 4.05473 108.973 13.2747 108.973 13.2747C102.483 9.30473 79.7032 1.76473 74.3532 1.11473C69.5432 0.534729 50.9532 -2.27527 28.8132 4.19473H28.8032C26.8832 4.75473 24.9432 5.38473 22.9832 6.09473C8.77315 11.2147 0.0931549 11.9147 0.0931549 11.9147V11.9047Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M0.0931549 11.9047C8.71315 21.6247 29.5232 17.7447 47.0832 17.0547C52.5532 16.8347 57.7032 16.9347 62.0732 17.8447C46.2532 10.5047 29.1232 12.7747 16.5032 13.7447C9.06315 14.3147 3.19315 14.4547 0.103153 11.8947L0.0931549 11.9047Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M125.783 20.4847C127.193 24.3647 132.133 24.3647 136.153 24.2947C138.613 24.2547 140.733 24.1747 141.463 24.9447C140.493 21.2247 134.623 20.2647 130.273 20.1947C128.193 20.1647 126.453 20.3247 125.783 20.4847Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M49.9932 4.62473C51.3132 12.0847 61.4332 15.4547 74.4532 16.9547C84.6932 17.4347 88.5932 12.7147 103.633 13.0847C80.8832 12.3947 55.3032 12.9047 49.9832 4.63473L49.9932 4.62473Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M28.8232 4.18473C42.8032 1.99473 54.9632 0.0647287 74.2432 3.35473C82.5432 4.04473 108.973 13.2647 108.973 13.2647C102.483 9.29473 79.7032 1.75473 74.3532 1.10473C69.5432 0.524729 50.9532 -2.28527 28.8132 4.18473H28.8232Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M16.5032 13.7547C26.9932 13.5647 30.0832 17.3647 47.0832 17.0547C52.5532 16.8447 57.7032 16.9347 62.0732 17.8547C46.2532 10.5147 29.1232 12.7847 16.5032 13.7547Z"
        fill="#231F20"
      />
      <path
        className="path-2"
        d="M13.1732 20.1247C4.14315 19.0247 0.283152 15.1847 0.283152 15.1847C-0.826848 19.7547 0.903151 23.4147 13.1732 20.1247Z"
        fill="#231F20"
      />
    </svg>
  );
};
