import React from "react";
import './index.css';
import googleIcon from './../../../assets/images/google.png';
function AuthPage() {


  const google = () => {
    window.open("https://auth.hireacar.pk/auth/google", "_self");
  };

  

  return (

    <div className="login">


      <form className="authform">
        <h1 className="loginTitle">Login With</h1>

        <div className="left">
          <div className="loginButton google" onClick={google} style={{color:"white"}}>
            <img src={googleIcon} alt="Google Icon" className="icon" />
            Google
          </div>


        </div>

        <footer />
      </form>
    </div >
  );
}

export default AuthPage;