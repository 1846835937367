import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";
import logo from "./../../../assets/img/logo-1.png";
import { Link, useNavigate } from "react-router-dom";
import logon from "./../../../assets/logon.png";
import { ProfileButton } from "../ProfileButton";
import { useMediaQuery } from "react-responsive";



const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;


const BarContainer = styled.div`
  ${tw`
    flex
    fixed
    bg-[#edf2f7]
    w-full
    h-10
    z-20
    justify-between
    items-center
    px-4
py-2
shadow-sm

  `}
`;

const LogoContainer = styled.a`
  ${tw`
    flex-shrink-0
    ml-2
  `}
`;

const LogoImage = styled.img`
  ${tw`
    h-full
    max-h-8
    w-auto // Set width to auto
    justify-center
    mt-1
  `}
`;

const UserList = styled.div`
  ${tw`
    flex
    items-center
    space-x-1
    md:relative
   static

  `}
`;

const MenuButton = styled.div`
  ${tw`
    flex
    items-start
    justify-end
    list-none
    uppercase
    font-bold
    text-black
    z-1
    mr-10
    cursor-pointer
    transition-opacity
    opacity-100
    hover:opacity-100
  `}
`;

const ProfileContainer = styled.div`
  ${tw`
    flex
    items-center
    space-x-1
  `}
`;

const ProfileImage = styled.img`
  ${tw`
    w-8
    h-8
    rounded-full
  `}
`;

const ProfileText = styled.span`
  ${tw`
    text-sm
  `}
`;

const DropdownMenu = styled.div`
  ${tw`
    absolute
    right-10
    top-2
    mt-8
    mr-10
    
    z-20
    
    uppercase
    opacity-0
    transition-opacity
    duration-300
  `}
  font-family: "Copperplate";
  animation: ${fadeInRight} 0.5s ease-in;




  ${UserList}:hover & {
    opacity: 1;
    visibility: visible;
  }


  

`;

const DropdownMenuMobile = styled.div`
  ${tw`
    absolute
    top-2
    mt-8
    z-20
    uppercase
    opacity-0
    transition-opacity
    duration-300
   
  `}
  animation: ${fadeInRight} 0.5s ease-in;

  font-family: "Poppins";
  margin-right: 2.5rem;
  margin-top: 2rem;
  text-transform: uppercase;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 300ms;
  right: 0.6rem;
  top: 2.7rem;

  ${UserList}:hover & {
    opacity: 1;
    visibility: visible;
  }


  @media (max-width: 430px) {
   
  padding-inline: 10rem;
  top: 3.5rem;
        


  }
  @media (max-width: 375px) {
   
    padding-inline: 8rem;
  top: 3.5rem;
          
  
  
    }
    @media (max-width: 320px) {
   
      padding-inline: 6rem;
            
  top: 3.5rem;
    
    
      }

`;


const DropdownButton = styled.button`
  ${tw`
  w-40
 
font-bold
    text-right
  uppercase
    hover:text-gray-600
    cursor-pointer
    text-sm
    mt-2
    border-none
  `}
  font-family: Copperplate;

`;

const HostButton = styled.button`
  ${tw`
  fixed
text-lg  
    text-white
    cursor-pointer
   
        z-10
    

   
  `}
  background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
  font-family: "Copperplate";
  
            border: none; /* Adding border to the info-row */
          

  
  
  // For screens smaller than 768px (mobile devices)
  @media (max-width: 768px) {
    ${tw`
   
      text-center
      
      mt-14
      
          `}
  width:100%;
  font-family: "Copperplate";
              border: 0.5px solid gray; /* Adding border to the info-row */


  }

  // For screens larger than 768px (laptops, desktops)
  @media (min-width: 769px) {
     ${tw`
           z-20

       mt-3
        ml-60
     `}
    position: absolute;
      width:160px;
  height:30px;
  border-radius:0.44rem;
    left: 50%;
    transform: translateX(-50%);
    
  }
cursor:pointer;
`;
interface NavbarProps {
  user: any;
}

const TopBar: React.FC<NavbarProps> = ({ user }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
const navigate = useNavigate()


const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
const isSmallMobile = useMediaQuery({ query: '(max-width: 430px)' })

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };



  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logout = () => {
    window.open("https://auth.hireacar.pk/auth/logout", "_self");
    navigate('/')
  };

  return (<div>
    <BarContainer>
      <LogoContainer href="/">
        <LogoImage src={logo} alt="Logo" />
      </LogoContainer>
      {/* <ProfileButton property1={"default"} className={undefined} user={user} /> */}
      <UserList>
        {user ? (
          <MenuButton onClick={handleDropdownToggle}>
            <ProfileContainer>
              <ProfileImage src={user.photos[0].value} />
              <ProfileText>{user.displayName}</ProfileText>
            </ProfileContainer>
          </MenuButton>
        ) : (
          <MenuButton onClick={handleDropdownToggle}>
            <ProfileContainer>
              <ProfileImage src={logon} />
            </ProfileContainer>
          </MenuButton>
        )}


        {

isMobile ?
<DropdownMenuMobile ref={dropdownRef} style={{ borderRadius: ".7rem",display:isDropdownOpen?'block':"none" }} >
  {user ? (
    <>
      <Link to="/UserProfile"  >
        <DropdownButton>Profile</DropdownButton>
      </Link>

      <Link to="/UserProfile" >
        <DropdownButton>My Cars</DropdownButton>
      </Link>
      <Link to="/add-car-form" >
        <DropdownButton>Add Your Car</DropdownButton>
      </Link>

      <Link to="/Contact" >
        <DropdownButton>Help Center</DropdownButton>
      </Link>
      <Link to="/Chat" >
        <DropdownButton>Messages</DropdownButton>
      </Link>
      <div  onClick={() =>  logout()}>
        <DropdownButton>Logout</DropdownButton>
      </div>
    </>
  ) : (
    <>
      <Link to="/auth-page" >
        <DropdownButton>Login / Signup</DropdownButton>
      </Link>

      <Link to="/Contact" >
        <DropdownButton>Help Center</DropdownButton>
      </Link>
   
   
      <Link to="/add-car-form" >
        <DropdownButton>Add Your Car</DropdownButton>
      </Link>
    </>
  )}
</DropdownMenuMobile>
:
<DropdownMenu ref={dropdownRef} style={{borderRadius:".7rem"}}>


  
{user ? (
  <>

    <Link to="/UserProfile">
      <DropdownButton>Profile</DropdownButton>
    </Link>

    <Link to="/Contact">
      <DropdownButton>My Cars</DropdownButton>
    </Link>
    <Link to="/add-car-form">
      <DropdownButton>Add Your Car</DropdownButton>
    </Link>
   
    <Link to="/Contact">
      <DropdownButton>Help Center</DropdownButton>
    </Link>
    <Link to="/Chat" >
        <DropdownButton>Chat</DropdownButton>
      </Link>
    <Link to=''>
      <DropdownButton onClick={logout}>Logout</DropdownButton>
    </Link>
  </>
) : (
  <>
    <Link to="/auth-page">
      <DropdownButton>Login / Signup</DropdownButton>
    </Link>
   
    <Link to="/Contact">
      <DropdownButton>Help Center</DropdownButton>
      </Link>
  
    <Link to="/add-car-form">
      <DropdownButton>Add Your Car</DropdownButton>
    </Link>
  </>
)}
</DropdownMenu>

        }
        
        



      </UserList>
    </BarContainer>
    <Link to="/add-car-form" >
      <HostButton>Add your car</HostButton> {/* Added button */}
    </Link>
  </div>
  );
};

export default TopBar;
