import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css'; // Import Flickity CSS
import MoonLoader from 'react-spinners/MoonLoader';
import carService from '../../services/carService';
import { Dispatch } from 'redux';
import { setTopCars } from './slice';
import { createSelector } from 'reselect';
import { makeSelectTopCars } from './../../containers/HomePage/selectors';
import { CarDisplay } from '../../components/CarDisplay';
import { useMediaQuery } from 'react-responsive';
import { GetCars_cars } from '../../services/carService/__generated__/GetCars';

const flickityOptions = {
  percentPosition: true,
  initialIndex: 2,
  cellAlign: 'center',
  contain: true,
  pageDots: false,
  prevNextButtons: true,
  wrapAround: true,
  autoPlay: 5000,
  draggable: true,
  arrowShape: { 
    x0: 10,
    x1: 60, y1: 50,
    x2: 70, y2: 40,
    x3: 30
  },

};

const CarouselContainer = styled.div`
${tw`
  w-full
  flex
  flex-col
  items-center
  justify-center
`}
`;

const CarouselHeading = styled.h2`
  ${tw`
    text-left
    mt-10 // No top margin to attach it to the top of its container
    mb-0 // No bottom margin to control the height
    pl-2 // No padding-left to attach it to the left border
  `}
  font-family:'Copperplate', 'Poppins', sans-serif;
  font-weight: 400; // Increased weight for better visibility
  font-size: 1.25rem; // Increased font-size for visibility but maintaining the height requirement
  color: white;
  background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
  width: 40%;
  height: 2rem; // Set the height to 4rem
  line-height: 2rem; // Ensure the text is vertically centered in the 4rem height
  overflow: hidden; // Ensures the content does not exceed the specified height
  align-items: center; // Centers the text vertically
  justify-content: space-around; // Aligns the text to the start (left)
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 50%; // Allow the heading to be more responsive on smaller screens
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 40%; // Adjust width for medium screens
  }

  @media (min-width: 1025px) {
    width: 30%; // Fixed width for larger screens
  }
`;

const LoadingContainer = styled.div`
${tw`
  flex
  justify-center
  items-center
  w-full
  h-screen
`}
`;

const actionDispatch = (dispatch: Dispatch) => ({
  setTopCars: (cars: GetCars_cars[]) => dispatch(setTopCars(cars)),
});

const stateSelector = createSelector(makeSelectTopCars, (topCars) => ({
  topCars,
}));

export function TopCarousel() {
  const [isLoading, setLoading] = useState(false);
  const { topCars } = useSelector(stateSelector);
  const { setTopCars } = actionDispatch(useDispatch());

  const fetchTopCars = async () => {
    setLoading(true);
    try {
      const cars = await carService.getCars();
      if (cars) {
        const sortedCars = cars.slice().sort((a, b) => b.dailyPrice - a.dailyPrice);
        setTopCars(sortedCars);
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopCars();
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        <MoonLoader />
      </LoadingContainer>
    );
  }

  return (

      <><CarouselHeading>Featured Cars</CarouselHeading><CarouselContainer>

      <Flickity className={'carousel'} elementType={'div'} options={flickityOptions} disableImagesLoaded={false} reloadOnUpdate static>
        {topCars.map((car: { id: React.Key | null | undefined; name: string; thumbnailUrl: string; dailyPrice: number; monthlyPrice: number; carType: string; year: number; City: string; gearType: string; photos: string[]; color: string; isAvailable: boolean; hourlyPrice: number; }) => (
          <div key={car.id}>
            <CarDisplay
              key={car.id}
              name={car.name}
              thumbnailUrl={car.thumbnailUrl}
              dailyPrice={car.dailyPrice}
              monthlyPrice={car.monthlyPrice}
              carType={car.carType}
              year={car.year}
              City={car.City}
              id={car.id}
              gearType={car.gearType}
              photos={car.photos}
              color={car.color}
              features={""}
              isAvailable={car.isAvailable}
              owner={""}
              location={0}
              hourlyPrice={car.hourlyPrice}
              gas={""} />
          </div>
        ))}
      </Flickity>
    </CarouselContainer></>
  );
}

