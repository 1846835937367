import { apolloClient } from "../../graphql";
import { GET_ALL_CARS } from "./queries";
import { GetCars_cars } from "./__generated__/GetCars";
import { GET_CAR_BY_ID } from './queries'; // Update this import with the correct path
import { GetCarById_car } from "./__generated__/GetCarById";

class CarService {
  [x: string]: any;
  public async getCarById(id: string): Promise<GetCarById_car | null> {
      const response = await apolloClient
        .query({ query: GET_CAR_BY_ID, variables: { id } })
        .catch((err) => {
          throw err;
        });

      if(response && response.data && response.data.car)
    return response.data.car as GetCarById_car;

    return null;
  }
 

  public async getCars(): Promise<GetCars_cars[]> {
    const response = await apolloClient
      .query({ query: GET_ALL_CARS })
      .catch((err) => {
        throw err;
      });

    if (response && response.data && response.data.cars)
      return response.data.cars as GetCars_cars[];

    return [];
  }
}

export default new CarService();