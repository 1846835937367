import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

import tw from 'twin.macro';
import styled, { keyframes } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from "@apollo/client";

import { ADD_NEW_CAR, UPDATE_CAR } from '../../services/carService/mutations';
import { UpdateCar } from '../../services/carService/mutations';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

import ClipLoader from "react-spinners/ClipLoader";
import { GetCarById } from '../../services/carService/__generated__/GetCarById';
import { GET_CAR_BY_ID } from '../../services/carService/queries';
import { GrGallery } from 'react-icons/gr';
import Switch from "react-switch";





const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeInDownContainer = styled.div`
  ${tw`
  
  flex
  flex-col
  
   
    bg-gray-200

  `}
  animation: ${fadeInDown} 0.8s ease forwards; /* Apply animation */
`;
const Container = styled.div`
  ${tw`
    py-6
    bg-gray-200
   px-6

  `}


`;



const FormContainer = styled.div`
  ${tw`
    
   
    py-6
  
    border
    border-gray-200
    rounded-md

  `}


`;


const SliderContainer = styled.div`
  ${tw`
    flex
    items-center
    mt-4
mb-4
  w-full
  `}
`;


const SliderValue = styled.span`
  ${tw`
    w-20
    text-right
  `}
`;


const CarTypeContainer = styled.div`
  ${tw`
    flex  
    flex-col  
    mt-8
    mb-8
  `}
`;

const Label = styled.h3`
  ${tw`
  uppercase

  `}
`;
const StyledCheckboxContainer = styled.div`
  ${tw`mt-3`}


`;

// Mock data


type Option = {
  label: string,
  value: string,
};

type YearOption = {
  label: string,
  value: number,
};
interface AddCarFormProps {
 
  user: any;

 
}


export const UpdateCarForm: React.FC<AddCarFormProps> = ({ user }) => {
  const { register, handleSubmit } = useForm();

  const [dailyPrice, setDailyPrice] = useState<number>(0);
  const [monthlyPrice, setMonthlyPrice] = useState<number>(0);
  const [hourlyPrice, setHourlyPrice] = useState<number>(0);
 
  const [isAvailable, setIsAvailable] = useState(false);

 
  const [Misctext, setMisctext] = useState('');
const [updateCarMutation, { loading:updateLoading, error:updateerror }] = useMutation<UpdateCar>(UPDATE_CAR);
const containerName = 'carpictures';
const blobEndpoint = 'https://hacblob.blob.core.windows.net/';
const sasToken =
    '?sp=racwdli&st=2024-04-30T04:14:42Z&se=2025-05-02T12:14:42Z&sv=2022-11-02&sr=c&sig=Gou1kUymMG%2Bq%2FudWWfVoDKoEdF%2FTNSbtYFGhBYJgAFo%3D'; // Replace this with the SAS token generated on the server-side.


    const [selectedImages, setSelectedImages] = useState<File[]>([]);
    const [uploadedImageUrls, setUploadedImageUrls] = useState<string[]>([]);
    const [checked, setChecked] = useState(false);


  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 786px)' })

  const { carId } = useParams();
  const { loading, error, data } = useQuery<GetCarById>(GET_CAR_BY_ID, {
    variables: { id: carId }
});
const [carPhotos, setCarPhotos] = useState<string[]>(data?.car.photos || []);
console.log('images',selectedImages)



useEffect(() => {
  if (data && data.car) {
    setDailyPrice(data.car.dailyPrice);
    setMonthlyPrice(data.car.monthlyPrice);
    setHourlyPrice(data.car.hourlyPrice);
    setMisctext(data.car.features);
    setIsAvailable(data.car.isAvailable);

    // Check if photos exist before setting state
    if (data.car.photos) {
      setCarPhotos(data.car.photos);
    }
    // Set other form values here
  }
}, [data]);









const onSubmit = async () => {
  try {
    const formData = {
     
      photos:uploadedImageUrls,
      dailyPrice:dailyPrice,
      hourlyPrice: hourlyPrice,
      monthlyPrice: monthlyPrice,
      features:Misctext ,
      isAvailable: isAvailable,
     
    };

    const { data: responseData } = await updateCarMutation({
      variables: {
        id: carId,
        updateCarData: formData,
      },
    });
    console.log('Car updated successfully:', responseData);
    // Handle success, e.g., show a success message
  } catch (error) {
    console.error('Error updating car:', error);
    // Handle error, e.g., show an error message
  }
};



 // Toggle switch state

 
 const handleSwitchChange = (checked: boolean) => {
  setIsAvailable(checked);
};
 
 

 


  const handleDailySliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDailyPrice(Number(event.target.value));
  };

  const handleMonthlySliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonthlyPrice(Number(event.target.value));
  };
  const handleHourlySliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHourlyPrice(Number(event.target.value));
  };
  
 


  const uploadImageToBlobStorage = async (file: File) => {
    try {
        const uniqueFileName = `${Date.now()}-${file.name}`;
        const urlWithSasToken = `${blobEndpoint}${containerName}/${uniqueFileName}${sasToken}`;

        // Manually determine the content type based on the file extension
        let contentType = 'image/jpeg'; // Default to JPEG type
        if (file.type === 'image/png' || file.name.endsWith('.png')) {
            contentType = 'image/png';
        }

        await fetch(urlWithSasToken, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': contentType,
                'x-ms-blob-type': 'BlockBlob', // Add this header
            },
        });

        // Return the actual image URL without SAS token
        const url = `${blobEndpoint}${containerName}/${uniqueFileName}`;
        return url;
    } catch (error) {
        console.error('Error uploading image:', error);
        return null;
    }
};

interface ImageUploadProps {
    onImageUrlsChange: (urls: string[]) => void;
}

    

   
const handleUploadPhotos = async () => {

  const urls = [];

  for (const image of carPhotos) {
      // Check if 'image' is already a File object or a string
      const imageUrl = typeof image === 'string' ? image : await uploadImageToBlobStorage(image);
      if (imageUrl) {
          urls.push(imageUrl);
      }
  }
  
  // Update the state with the actual image URLs after all images are uploaded
  setUploadedImageUrls(urls);
  setCarPhotos([]); // Clear the selected images after uploading
};


    
    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files && files.length > 0) {
          const newImages = Array.from(files).map(file => URL.createObjectURL(file));
          setCarPhotos(prevPhotos => [...prevPhotos, ...newImages]);
      }
      
  };
  
  const handleRemoveImage = (index: number) => {
      setCarPhotos(prevPhotos => prevPhotos.filter((_, i) => i !== index));
  };






const SuccessStep: React.FC = () => (
  <FadeInDownContainer>
    <h1>Thank you for submitting your car!</h1>
    <p>Your data is under review. You'll receive an email confirmation once your car is online.</p>
    <Link to="/UserProfile">
      <h4>Back to Profile</h4>
    </Link>
  </FadeInDownContainer>
);


  return (
    <Container> 

 

      <FormContainer>

      {!isSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)}> 

        
       
       
<>











<FadeInDownContainer>  
<Label > Add some photos of your car   </Label>



<div>


<div style={{ display: "flex",flexDirection:"column", alignItems: "center", justifyContent: "center", border: "1px solid rgba(0, 0, 0, 0.31)", backgroundColor: "#EEECEC", width: "100%",height:"228px",gap:".6rem"  }}>
    <GrGallery size={59} style={{ marginRight: "10px" }} />

    <label htmlFor="file-upload" style={{ cursor: "pointer",marginTop:"1rem",fontFamily:"poppins" }}>      Upload from your device </label>
    <input
        id="file-upload"
        type="file"
        accept="image/*"
        multiple // Allow selecting multiple images
        onChange={handleImageUpload}
        style={{ display: "none" }} // Hide the input element
    />
</div>



<div>
    {/* Display thumbnails of selected images */}
    {carPhotos.map((image, index) => (

<div key={index} style={{ position: 'relative', display: 'inline-block' }}>
<img
src={image}
alt={`Thumbnail ${index}`}
style={{ width: '150px', height: '150px', margin: '5px',objectFit:"fill" }}
/>
<button
onClick={() => handleRemoveImage(index)}
style={{ position: 'absolute', top: '5px', right: '5px', background: 'gray', border: 'none', cursor: 'pointer',color:"white" }}
>
&#x2715;
</button>
</div>
    ))}
</div>
<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>   
<button type="button" onClick={handleUploadPhotos}  style={{backgroundColor:"#21408E",borderRadius:"5px",color:"#fff",border:"none",marginBlock:".6rem",paddingInline:"25px",paddingBlock:"8px",fontSize:"16px",cursor:"pointer",fontFamily:"poppins"}}>
    Upload Photos
</button>
</div>



<div>
    {/* Display uploaded images */}
    {uploadedImageUrls.map((url, index) => (
        <img
            key={index}
            src={url} // Use the URL directly from the state
            alt={`Uploaded Image ${index}`}
            style={{ width: '100px', height: '', margin: '5px' }}
        />

    ))}
</div>
</div>


<Label > Select Your car Availability </Label>

        
<StyledCheckboxContainer>
<Switch
           
            checked={isAvailable}
    onChange={handleSwitchChange}
    onColor="#86d3ff"
    onHandleColor="#21408E"
    handleDiameter={20}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch"
          />
            </StyledCheckboxContainer>





 

<Label > Select Your cars Price </Label>
     
         

        

<div className="div" style={{display:"flex",justifyContent:"space-between",alignItems:"center",flexDirection:isMobile?'column':'row'}}>   

         
            <div className="mb-4">
              <Label className="block">Hourly Price</Label>
              <SliderContainer>
                <input
                  type="range"
                  min={100}
                  max={10000}
                  step={100}
                  value={hourlyPrice}
                  onChange={handleHourlySliderChange}
                  className="w-full"
                  style={{accentColor:"#21408E"}}
                />
                <SliderValue>{hourlyPrice} PKR</SliderValue>
              </SliderContainer>
            </div>  
      
            <div className="mb-4">
              <Label className="block">Daily Price</Label>
              <SliderContainer>
                <input
                  type="range"
                  min={0}
                  max={100000}
                  step={100}
                  value={dailyPrice}
                  onChange={handleDailySliderChange}
                  className="w-full"
                  style={{accentColor:"#21408E"}}

                />
                <SliderValue>{dailyPrice} PKR</SliderValue>
              </SliderContainer>
            </div>
         
   
            <div className="mb-4">
              <Label className="block">Monthly Price</Label>

              <SliderContainer>

                <input
                  type="range"
                  min={''}
                  max={1000000}
                  step={100}
                  value={monthlyPrice}
                  onChange={handleMonthlySliderChange}
                  className="w-full"
                  style={{accentColor:"#21408E"}}

                />
                <SliderValue>{monthlyPrice} PKR</SliderValue>

              </SliderContainer>
            </div>

          

</div>

          
            
            <div className="mb-4">
              <Label className="block">Features </Label>

              <textarea

                value={Misctext}
                onChange={(e) => setMisctext(e.target.value)}
                rows={6}
                style={{width:"100%",border:'1px solid gray',borderRadius:"10px"}}
              />

            </div>
        
            <button type="submit" style={{backgroundColor:"#21408E",borderRadius:"5px",color:"#fff",border:"none",marginBlock:".6rem",paddingInline:"25px",paddingBlock:"8px",fontSize:"16px",cursor:"pointer"}}> {isLoading ? (
              <div style={{display:"flex",gap:".7rem",justifyContent:"center",alignItems:"center"}}>
                <span style={{fontSize:"16px",color:"#fff",fontFamily:"poppins"}}>Submitting...</span>
                <ClipLoader color="#fff" size={16} />
              </div>
            ): (
           <span style={{fontFamily:"poppins"}}>   Update </span> 

            )
            }
              
              
              </button>
        
   
</FadeInDownContainer>






</>
     



  

        </form>
): (
  // Render success step if form is submitted
  <SuccessStep />
)}
      </FormContainer>

  


    </Container>

  );



}
export default UpdateCarForm;
