import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {  useParams } from 'react-router-dom';
import { GET_ALL_CARS} from '../../services/carService/queries';
import { ICarProps } from '../../components/car';
import styled from 'styled-components';
import tw from 'twin.macro';
import CarDisplay from '../../components/CarDisplay';

// Styled Components


const ResultsContainer = styled.div`
  ${tw`  
  flex flex-wrap justify-center items-center p-5 bg-gray-100 min-h-screen gap-1 md:gap-1`}`;

const LoadingMessage = styled.p`
  ${tw`text-xl text-blue-500`}
`;

const ErrorMessage = styled.p`
  ${tw`text-xl text-red-500`}
`;
const ResultsHeader = styled.p`
  ${tw`py-2 text-xl text-gray-700`}
  margin-top: 0; // Remove any top margin if present
  padding-top: 8rem; // Remove any top padding if present
`;


const SearchResultsByBrandName = () => {
  const { brandName } = useParams();
  const [filteredCars, setFilteredCars] = useState([]);

  const { loading, error, data } = useQuery(GET_ALL_CARS); // Assuming you're fetching all cars
  console.log(`Brand Name for Filtering: '${brandName}'`);

  useEffect(() => {
    if (!loading && data && data.cars && brandName) {
      console.log("Car Names Available:", data.cars.map((car: { name: any; }) => car.name));
      const filtered = data.cars.filter((car: { name: string; }) =>
        car.name.toLowerCase().includes(brandName.toLowerCase())
      );
      console.log("Filtered Cars:", filtered); // This will show which cars passed the filter
      setFilteredCars(filtered);
    }
  }, [data, loading, brandName]);
  


  if (loading) return <LoadingMessage>Loading...</LoadingMessage>;
  if (error) return <ErrorMessage>Error: {error.message}</ErrorMessage>;

  return (
    <div>
      <ResultsHeader>
        Showing search results for "{brandName}"
      </ResultsHeader>
      <ResultsContainer>
      {filteredCars.map((car: ICarProps) => (
          <CarDisplay key={car.id} {...car} />
        ))}
      </ResultsContainer>
    </div>
  );
};

export default SearchResultsByBrandName;
