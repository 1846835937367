
const { gql } = require('@apollo/client');



export interface NewLocationData {
    city: string;
    longitude: number;
  latitude: number;
}




export const ADD_NEW_LOCATION_MUTATION = gql`
  mutation AddNewLocation($newLocationData: NewLocationInput!) {
    addNewLocation(newLocationData: $newLocationData) {
      id
      city
      longitude
      latitude

    }
  }
`;

// const newLocationData = {
//   city: "lahore",
//   area: "ic",
//   postalCode: "54000",
// };

// client
//   .mutate({
//     mutation: ADD_NEW_LOCATION_MUTATION,
//     variables: {
//       newLocationData: newLocationData,
//     },
//   })
//   .then((response) => {
//     const addedLocation = response.data.addNewLocation;
//     console.log(addedLocation);
//     // Handle the added location data in your UI or application logic
//   })
//   .catch((error) => {
//     console.error(error);
//   });




