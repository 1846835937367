import React, { useEffect, useRef, useState } from "react";
import "./CarDisplay.css";
import { ICarProps } from "../car";
import 'react-image-lightbox/style.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartEmpty, faL } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartFilled } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-responsive-modal/styles.css';




export function CarDisplay(props: ICarProps) {


  const {
    name, dailyPrice, City, gearType, photos, year, color  } = props;
  const [photoIndex, setPhotoIndex] = useState(0);
  const navigate = useNavigate();
  const [isFavorited, setIsFavorited] = useState(false);
  const [, setIsModalOpen] = useState(false);
  const [, setSelectedPhotoIndex] = useState(0);
  function slugify(text: string) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .trim();                        // Trim - from start and end of text
  }
  
  const handleCarClick = () => {
    // Assuming `props` includes `city`, `carType`, and `id` fields

    navigate(`/rent-a-car/${slugify(props.City)}/${slugify(props.carType)}/${slugify(props.name)}/${props.id}`, { state: { carProps: props } });
  };
  // const handleImageClick = (index: number, event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
  //   event.stopPropagation(); // Prevent the click from propagating to the outer div
  //   setLightboxOpen(true);
  //   setPhotoIndex(index);
  // };
  // useEffect(() => {
  //   const imagesLoaded = photos.every((photo) => {
  //     const image = new Image();
  //     image.src = photo;
  //     return image.complete;
  //   });

  //   if (imagesLoaded) {
  //     setSliderReady(true);
  //   } else {
  //     // Listen for the load event on images if they are not loaded yet
  //     photos.forEach((photo) => {
  //       const image = new Image();
  //       image.src = photo;
  //       image.onload = () => {
  //         // Check again if all images are loaded
  //         const allImagesLoaded = photos.every((photo) => {
  //           const img = new Image();
  //           img.src = photo;
  //           return img.complete;
  //         });
  //         if (allImagesLoaded) {
  //           setSliderReady(true);
  //         }
  //       };
  //     });
  //   }
  // }, [photos]); // Dependency array includes 'photos' to rerun the effect when 'photos' changes
  // This function handles opening the modal and setting the selected photo index
  const openModal = (index: number) => {
    setSelectedPhotoIndex(index);
    setIsModalOpen(true);
  };

  // This function handles closing the modal

  const settings = {
    showArrows: true,
    autoPlay: true,
    interval: 3000,
    infiniteLoop: true,
    showThumbs: false,
    showStatus: false,
    useKeyboardArrows: true,
    swipeable: true,
    dynamicHeight: false
  };


 
  


  const handleFavoriteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent the click from propagating to the outer div
    setIsFavorited(!isFavorited);
    // TODO: Call API to update favorite status in the database
  };

  return (

    <div className={`car-display `}>
      <div className="overlap-group-3" style={{marginRight:"0rem"}}>
        <Carousel {...settings}>
              {photos.map((photo, index) => (
          <div key={index} className="image-container">
            <img
              className="CAR"
              src={photo}
              alt={`Car ${index}`}
              onClick={() => openModal(index)}
            />
              <button className={`favorite-button ${isFavorited ? 'favorited' : ''}`} onClick={(event) => { handleFavoriteClick(event); }}>
                {isFavorited ? <FontAwesomeIcon icon={faHeartFilled} /> : <FontAwesomeIcon icon={faHeartEmpty} />}
              </button>
            </div>
          ))}
        </Carousel>
        {/* {isLightboxOpen && (
          <Lightbox
            mainSrc={photos[photoIndex]}
            nextSrc={photos[(photoIndex + 1) % photos.length]}
            prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
            onCloseRequest={() => setLightboxOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
          />
        )} */}


        <div onClick={handleCarClick} className={`car-display`}> {/* Added onClick here */}

        <div className="infobox">

        <div className="Name" >{name}</div>
        <div className="loc">{City}</div>

        <div className="element-reviews">
          <span className="span">0 </span>
          <span className="text-wrapper-4">Reviews</span>
        </div>
        <div className="rectangle-2" />
        <div className="rs">PKR {dailyPrice}/ Day</div>
        

        {/* <Driver className="driver-instance" /> */}
        <div className="info">
          {/* <Modelicon className="modelicon-instance" /> */}
          <div className="element">{year}</div>
    

          <div className="SUV">with driver</div>
          <div className="automatic">{gearType}</div>
          <div className="red">{color}</div>
          </div>

          </div>



          {/* <img className="petrolicon" alt="Petrolicon" src={petrolicon} /> */}
          {/* <img className="settingsicon" alt="Settingsicon" src={settingsicon} /> */}

</div>
      </div>
      </div>

  );
};
export default CarDisplay;