import styled from 'styled-components';
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'; // Importing icons

const SocialMediaBar = styled.div`
  position: fixed;
  right: 0;
  bottom: 0; // Position it at the bottom on mobile
  transform: translateX(0%);
  display: flex;
  flex-direction: column; // Horizontal layout on mobile
  align-items: center;
  background: rgba(255, 255, 255, 0.4); // Slightly transparent background
  border-radius: 10px 0px 0 0;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  padding: 2px 7px; // Reduced padding

  @media (min-width: 768px) {
    top: 50%;
    right: 0;
    bottom: auto;
    transform: translateY(-50%);
    flex-direction: column; // Vertical layout on desktop
    border-radius: 10px 0 0 10px;
    padding: 7px;
    background: #fff;
  }
`;

const IconWrapper = styled.a`
  color: inherit;
  text-decoration: none;
  padding: 5px; // Smaller padding for mobile
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; // Smaller width for mobile
  height: 30px; // Smaller height for mobile

  &:hover {
    background-color: #f0f0f0;
  }

  @media (min-width: 768px) {
    width: 60px; // Larger width for desktop
    height: 60px; // Larger height for desktop
  }
`;

const FacebookIcon = styled(FaFacebookF)`
  font-size: 16x; // Smaller icon size for mobile

  @media (min-width: 768px) {
    font-size: 16px; // Larger icon size for desktop

  }
  color: #3b5998;
  font-size: 16px;
`;

const LinkedInIcon = styled(FaLinkedinIn)`
  font-size: 20px; // Smaller icon size for mobile

  @media (min-width: 768px) {
    font-size: 16x; // Larger icon size for desktop
  }
  color: #0e76a8;
  font-size: 16px;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 16px; // Smaller icon size for mobile

  @media (min-width: 768px) {
    font-size: 24px; // Larger icon size for desktop
  }
  color: #3b5998;
  font-size: 16px;
`;

const SocialSidebar = () => {
  return (
    <SocialMediaBar>
      <IconWrapper href="https://www.facebook.com/hireacarpk" target="_blank" rel="noopener noreferrer">
        <FacebookIcon />
      </IconWrapper>
      <IconWrapper href="https://www.linkedin.com/company/hireacar-pk" target="_blank" rel="noopener noreferrer">
        <LinkedInIcon />
      </IconWrapper>
      <IconWrapper href="https://www.instagram.com/hireacarpk" target="_blank" rel="noopener noreferrer">
        <InstagramIcon />
      </IconWrapper>
    </SocialMediaBar>
  );
};

export default SocialSidebar;
    