import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { deleteCar, deleteData } from '../../services/carService/mutations'; // Import the DELETE_CAR mutation
import tw  from 'twin.macro'; // Import styled and tw from twin.macro
import styled from 'styled-components';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FiMoreVertical } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { GET_USER_PROFILE } from '../../services/userService/queries';

interface CarDropdownProps {
  carId: string | null;
  
}

// Define styled components using tw and styled
const DropdownButton = styled.button`
  ${tw`relative  transition-all duration-200  p-2  flex flex-row items-center justify-center bg-white gap-2 rounded-lg border border-zinc-200`}
`;

const DropdownPanel = styled.div`
  ${tw`absolute shadow-lg -bottom-32 left-0 w-full h-max p-1 bg-white border border-zinc-200 rounded-lg flex flex-col gap-2`}
`;

const DropdownOption = styled.span`
  ${tw`flex flex-row gap-2 items-center hover:bg-zinc-100 p-2 rounded-lg`}
`;

const CarDropdown: React.FC<CarDropdownProps> = ({ carId }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [deleteMutation] = useMutation<deleteData>(deleteCar);
  const storedUser:any = localStorage.getItem('user');

  // Parse the JSON string back into an object
  const userObject = JSON.parse(storedUser);
  const { loading, error, data,refetch } = useQuery(GET_USER_PROFILE, {
    variables: { id: userObject.userId }
});



  const handleDelete = async () => {
    try {
      const data = await deleteMutation({ variables: { id: carId } });
   
      if(data )
      {
        toast.success('Your Car Delete successfully')
        refetch()
      }
    } catch (error) {
      console.error('Error deleting car:', error);
      // Handle error if necessary
    }
  };

  if (!carId) {
    // Handle the case when carId is undefined or null
    return null;
  }


  return (
    <DropdownButton
    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
  >
    <FiMoreVertical  size={20} />
    {isDropdownOpen && (
      <DropdownPanel>
          
        <DropdownOption onClick={handleDelete}>
          
          <MdDelete size={22} color="#000" />
          
        </DropdownOption>
     
        <DropdownOption>
        <Link to={`/CarUpdate/${carId}`}>
        <MdEdit size={22} color="#000" />
          </Link>
        </DropdownOption>

      </DropdownPanel>
    )}
  </DropdownButton>
  );
};
export default CarDropdown;
