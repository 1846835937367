import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import { useLocation, useParams } from "react-router-dom";
import CarService from './../../services/carService/index';
import { GetCarById_car } from '../../services/carService/__generated__/GetCarById';
import { FaCalendarAlt, FaPaintBrush, FaCar, FaCog, FaGasPump, FaCity, FaCheck, FaHourglass, FaCalendarDay, FaCalendarWeek } from 'react-icons/fa';
import tw from 'twin.macro';
import Accordion from './accordian';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import { AiTwotoneLike } from "react-icons/ai"; 
import { MdOutlineMessage, MdOutlineCall } from "react-icons/md";
import './style.css'
import { useQuery, gql, useMutation } from '@apollo/client';
import { GET_USER_PROFILE } from "../../services/userService/queries";
import { useNavigate } from 'react-router-dom';
import googleIcon from './../../../assets/images/google.png';
import { ChatState } from '../../../Context/ChatProvider';
import { Helmet } from 'react-helmet';


const CarDetailsWrapper = styled.div`

${tw`
py-20
p-4
`}


  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const MapHeading = styled.h3`

${tw`
text-center
uppercase
text-white



`}
  background:#21408E;
  borderRadius:5px

`;
const CarHeading = styled.h3`

${tw`
text-center
uppercase
text-white
p-2


`}
  background: #21408E;

  display: flex;
  flex-direction: column;
`;
const ImageSection = styled.div`
  ${tw`
    w-full
    
    grid
    lg:grid-cols-2
    grid-cols-1
  `}


  /* Define grid areas */
  grid-template-areas:
    'preview-thumbnails'
    'map';

  /* Larger screens */
  @media (min-width: 1024px) {
    grid-template-areas:
      'preview-thumbnails map';
  }

  .preview-thumbnails {
    grid-area: preview-thumbnails;
    position: relative; /* Make it a relative container for arrows */
    cursor:pointer;
    img.preview {
      margin-top:66px;
      width: 100%;
      height: 300px;
      object-fit: cover;

    }
    .thumbnails {

      display: flex;
      overflow-x: hidden;
      gap: 0.3rem;
      img {
        width: 150px;
        height: 100px;
        object-fit: cover;
                  border-radius: 1rem;

      }
    }

   .arrow {
      position: absolute; /* Positioned relative to preview-thumbnails */
      top: 50%;
      transform: translateY(-50%);
      font-size: 2rem;
      cursor: pointer;
      &:hover {
        color: #007bff;
      }
    }
    .left-arrow { left: 10px; }
    .right-arrow { right: 10px; }
  }

`;
const MapSection = styled.div`

${tw`
`}
  grid-area: map; /* Added grid area */

  height: 350px;
  width: 100%;
              position: center; 


  .heading {
    font-size: 1.2rem;
    text-align: center;
                  border: 1px solid gray; /* Adding border to the info-row */

  }

`;


const InfoSection = styled.div`
  ${tw`
  text-lg
    grid
    lg:grid-cols-2
    grid-cols-1
    gap-2
    text-left
    p-2
  `}

  .info-row {
    ${tw`
      flex
      flex-col
      gap-2
      
    `}


    .info-item {
      ${tw`
        flex
        items-center
        text-lg
        p-2
        
        gap-4
      `}
  border-radius: 0.5rem;
              border: 1px solid #BDBDBD; /* Adding border to the info-row */
  letter-spacing: 0.1em;
  font-size:15px;
  font-weight:300;
  font-family: Poppins;
  paddingInline:20px;
  font-family:Poppins


    }
  }
`;
const BottomContainer = styled.div`

${tw`

  justify-center
    grid
    lg:grid-cols-2
    grid-cols-1
gap-y-12    
`}
  border-radius: 1rem;

`;
const PricesSection = styled.div`

${tw`

text-white
justify-start
text-left
uppercase
`}
  background:gray;
height:300px;
  align-items:center;
  .price-item {
    font-size: 1.5rem;
    
    padding: 1.5rem;
          border: 1px solid gray; /* Adding border to the info-row */
  }
`;



const OwnerInfoSection = styled.div`
${tw`flex`}
object-fit:cover;
  flex-direction: column;
  align-items: center;
  .owner-card {
    display: flex;
    flex-direction: column;
    align-items: center;
     padding: 0rem;
    width: 100%;
    height:100%;
    img {
      width: 50px;
      height:50px;
    }
           border: 1px solid #B1B3B4; /* Adding border to the info-row */


`;


const Button = styled.button`
  background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
  color: #fff;
  padding: .5rem;
  border:none
`;


const FeaturesContainer = styled.div`
  ${tw`
items-center
p-2
    `}
    min-height:200px;
    border-radius:0.62rem;
    border: 1px solid #BDBDBD; /* Adding border to the info-row */

`;
const ReviewSection = styled.div`
  ${tw`
    p-0
    text-center
    border-t-2
    border-b-2
    border-gray-300
  `}
          border: 1px solid white; /* Adding border to the info-row */


  p {
    ${tw`
      text-lg
      font-semibold
      text-gray-700
    `}
  }
`;
// Media queries for responsiveness
const customMediaQuery = (maxWidth: number) => `
  @media (max-width: ${maxWidth}px) {
    ${InfoSection} {
      grid-template-columns: 2fr;
    }
  }

`;

const LoadingContainer = styled.div`
  ${tw`
    justify-center
    items-center
    w-full
    h-screen
  `}
`;
const ReviewsContainer = styled.div`
  ${tw`
    p-4
    border
    border-gray-300
    rounded-lg
    justify-center
    overflow-hidden
  `}
`;


const ReviewsSection = styled.div`
  ${tw`
    p-4
    border-t-2
    border-b-2
    border-gray-300
    flex
    flex-col
    gap-4
  `}

  .review {
    ${tw`
      flex
      flex-col
      gap-2
      p-2
      border
      border-gray-200
      rounded-lg
    `}
    
    .review-header {
      ${tw`
        flex
        justify-between
        items-center
      `}

      .reviewer-name {
        ${tw`font-semibold`}
      }

      .review-date {
        ${tw`text-sm text-gray-400`}
      }
    }

    .review-content {
      ${tw`text-lg`}
    }

    .review-rating {
      ${tw`text-yellow-400`}
    }
  }
`;


const IconWrapper = styled.span`
  margin-right: 8px; `;

const media = {
  custom: customMediaQuery,
  desktop: customMediaQuery(922),
  tablet: customMediaQuery(768),
  phone: customMediaQuery(576),
};



const CarPage: React.FC<{ userId: string | null }> = ({ userId }) => {
  const { loading, error, data } = useQuery(GET_USER_PROFILE, {
    variables: { id: userId }
});

const { selectedChat, setSelectedChat, user, notification, setNotification } =
ChatState();


  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  const navigate = useNavigate();

  const handleThumbnailClick = (index: number) => {
    setCurrentImageIndex(index);
  };
  const nextImage = () => {
    if (car && car.photos) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % car.photos.length);
    }
  };

  const prevImage = () => {
    if (car && car.photos) {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + car.photos.length) % car.photos.length);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  
  };

  const reviews = [
    {
      name: 'Ali Mirza',
      date: '2023-08-10',
      rating: 5,
      content: 'Excellent car! Had a great experience.',
    },
    {
      name: 'Hira Khan',
      date: '2023-07-15',
      rating: 4,
      content: 'Very comfortable and smooth ride.',
    },
    // Add more reviews as needed
  ];


  const [car, setCar] = useState<GetCarById_car | null>(null);
  const carService = CarService;
  const { City, name, carType, id: carId } = useParams<{ City: string, carType: string,name:string, id: string }>();

const isMobile = useMediaQuery({ query: '(max-width: 786px)' })
const isSmallMobile = useMediaQuery({ query: '(max-width: 430px)' })




  const handleApiLoaded = (map: any, maps: any) => {
    // Add a circle
    new maps.Circle({
      strokeColor: '#FF000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF000',
      fillOpacity: 0.35,
      map,
      center: { lat: car?.location.latitude, lng: car?.location.longitude },
      radius: 1000 // Radius in meters
    });
  };
  useEffect(() => {
    const fetchCarDetails = async () => {
      if (carId) { // Check if carId is defined
        const carDetails = await carService.getCarById(carId); // Corrected the method name
        setCar(carDetails);
      } else {
        console.error("carId is undefined");
      }
    };

    fetchCarDetails();
  }, [carId, carService]);

  if (!car) {
    return <div>Loading...</div>;
  }


  const google = () => {
    const returnUrl = window.location.href;
    window.open(`https://auth.hireacar.pk/auth/google?returnUrl=${encodeURIComponent(returnUrl)}`, "_self");
  };
  
  // const accessChat = async (userId) => {
  //   console.log(userId);

  //   try {
  //     setLoadingChat(true);
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     };
  //     const { data } = await axios.post(`/api/chat`, { userId }, config);

  //     if (!chats.find((c) => c._id === data._id)) setChats([data, ...chats]);
  //     setSelectedChat(data);
  //     setLoadingChat(false);
  //     onClose();
  //   } catch (error) {
  //     toast({
  //       title: "Error fetching the chat",
  //       description: error.message,
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //       position: "bottom-left",
  //     });
  //   }
  // };

  

  return (
    <CarDetailsWrapper>
<Helmet>
  <title>{`${car.name} - ${car.year} in ${City}`}</title>
  <meta name="description" content={`Learn more about our ${name} ${carType} in ${City}, perfect for your needs!`} />

  {/* Open Graph Meta Tags */}
  <meta property="og:title" content={`${car.name} - ${car.year} in ${City}`} />
  <meta property="og:description" content={`Rent this ${carType} in ${City} for as low as PKR ${car.dailyPrice}/day.`} />
  <meta property="og:image" content={car.photos[0]} /> {/* Use the first image in car.photos */}
  <meta property="og:url" content={window.location.href} />
  <meta property="og:type" content="website" />

  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title"   
 content={`${car.name}   
 - ${car.year} in ${City}`} />
  <meta name="twitter:description" content={`Rent this ${carType} in ${City} for as low as PKR ${car.dailyPrice}/day.`} />
  <meta name="twitter:image" content={car.photos[0]} />

</Helmet>
      {!isMobile && 
      <CarHeading>
        
        {car.name}-{car.year}
      </CarHeading>
}
      <ImageSection>
      { isMobile ?
      <div style={{marginBlock:"1rem"}}>

    <Slider {...settings} >
           

            {car.photos.map((car, index) => (
              <div key={index} style={{marginBlock:"20px",width:"100%"}}>
            <img  src={car} alt="Car Preview" height={230} style={{width:'100%',objectFit:"cover",borderRadius:"8px"}} />
               
              </div>
            ))}
                  </Slider>
      </div>
:
        <div className="preview-thumbnails">
          <div className="preview-container"> {/* Container for preview image and arrows */}
            <img className="preview" src={car.photos[currentImageIndex]} alt="Car Preview" />
            <div className="arrow left-arrow" onClick={prevImage}>&lt;</div>
            <div className="arrow right-arrow" onClick={nextImage}>&gt;</div>
          </div>
          <div className="thumbnails">
            {car.photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Car Thumbnail ${index}`}
                onClick={() => handleThumbnailClick(index)} // Add onClick handler
              />
            ))}
          </div>
        </div>
}

{ isMobile ? <div  style={{paddingInline:"20px",paddingBlock:"8px",border:"1px solid #B1B3B4",marginTop:"1rem",borderRadius:"10px"}}>

<div  style={{display:"flex",justifyContent:"space-between",alignItems:'center'}} >
<div style={{fontSize:"24px",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem"}}>{car.name}</div>
<AiTwotoneLike size={31} color='#B1B3B4'/>
</div>
<div style={{fontSize:"1rem",color:"#B1B3B4",fontWeight:'400',fontFamily:"Poppins",lineHeight:"2rem"}}>{car.location.city}</div>

<div style={{display:"flex",alignItems:'center',gap:"1.56rem"}}>
<FaHourglass size={20} color='#B1B3B4'/>
<div style={{fontSize:"14px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2rem"}}>
  <span style={{fontSize:"16px",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem",paddingRight:"5px"}}>   
HOURLY PRICE:
</span>
 PKR {car.hourlyPrice}
</div>

</div>
<div style={{display:"flex",alignItems:'center',gap:"1.56rem"}}>

<FaCalendarDay size={20} color='#B1B3B4'/>
<div style={{fontSize:"14px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2rem"}}>
  <span style={{fontSize:"16px",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem",paddingRight:"5px"}}>   
  DAILY PRICE:
</span>
 PKR {car.dailyPrice}
</div>

</div>
<div style={{display:"flex",alignItems:'center',gap:"1.56rem"}}>

<FaCalendarWeek size={20} color='#B1B3B4'/>
<div style={{fontSize:"14px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2rem"}}>
  <span style={{fontSize:"16px",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem",paddingRight:"5px"}}>   
  MONTHLY PRICE: 
</span>
 PKR {car.monthlyPrice}
</div>

</div>


</div> : (

        <MapSection>
          <MapHeading>location</MapHeading>
          <GoogleMapReact

            bootstrapURLKeys={{ key: 'AIzaSyBwY6GpbILHBgLOeMpMraMWi20LvOSLZos' }}
            defaultCenter={{ lat: car.location.latitude, lng: car.location.longitude }}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {/* Markers */}


          </GoogleMapReact>
        </MapSection>
)
} 

      </ImageSection>
     
      { isMobile ?
      <div style={{fontSize:"16px",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem",paddingInline:"20px",}}>Featires & Specifications</div>
      :
      <MapHeading>Featires & Specifications</MapHeading>
      }
      <InfoSection>

        <div className="info-row">
          <div className="info-item"><IconWrapper><FaCalendarAlt  color='#B1B3B4' size={24} /> </IconWrapper> Year: {car.year}</div>
          <div className="info-item"><IconWrapper><FaPaintBrush  color='#B1B3B4' size={24} /> </IconWrapper>Color: {car.color}</div>
          <div className="info-item"><IconWrapper><FaCar  color='#B1B3B4' size={24} /> </IconWrapper>CarType: {car.carType}</div>
          <div className="info-item"><IconWrapper><FaCog  color='#B1B3B4' size={24} /> </IconWrapper>Transmission: {car.gearType}</div>
        </div>
        <div className="info-row">
          <div className="info-item">
            <IconWrapper> <FaCity  color='#B1B3B4' size={24} /></IconWrapper>
            {car.isAvailable ? 'Driver: Available' : 'Driver: Not Available'}
          </div>
          <div className="info-item"><IconWrapper><FaGasPump  color='#B1B3B4' size={24} /> </IconWrapper>Gas: {car.gas}</div>
          <div className="info-item"><IconWrapper><FaCity color='#B1B3B4' size={24}  /> </IconWrapper>City: {car.City}</div>
          <div className="info-item"><IconWrapper><FaCheck  color='#B1B3B4' size={24} /> </IconWrapper>Availability: {car.isAvailable ? 'Available' : 'Not Available'}</div>
        </div>
      </InfoSection>
      {isMobile ?
      <div style={{fontSize:"16px",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem",paddingInline:"20px",}}>Additional Information</div>

      :
      <div>Additional Information</div>

      }
      <FeaturesContainer>
        <div><IconWrapper><FaCheck /> </IconWrapper>{car.features}</div>
      </FeaturesContainer>
    

  <a href={data?.user && '#ownerinfo'} className='book_button' style={{textDecoration:"none",color:"#fff",cursor:"pointer"}} onClick={() => {
  if (!data?.user) {
    google(); // <-- Corrected call to the google function
  } 
}}>How To Book</a>




{
isMobile && 

      <div style={{width:"100%",height:"350px",marginBottom:"5rem"}}>
         <div style={{fontSize:"16px",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem",paddingInline:"20px",marginBlock:'1rem'}}>Location </div>
          <GoogleMapReact

            bootstrapURLKeys={{ key: 'AIzaSyBwY6GpbILHBgLOeMpMraMWi20LvOSLZos' }}
            defaultCenter={{ lat: car.location.latitude, lng: car.location.longitude }}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {/* Markers */}


          </GoogleMapReact>
        </div>

}



      <BottomContainer>

        <div>

          <Accordion />
        </div>
        { !isMobile &&
        <PricesSection>

          <div className="price-item"><IconWrapper><FaHourglass /> </IconWrapper>Hourly Price: PKR{car.hourlyPrice}</div>
          <div className="price-item"><IconWrapper><FaCalendarDay /> </IconWrapper>Daily Price: PKR{car.dailyPrice}</div>
          <div className="price-item"><IconWrapper><FaCalendarWeek /></IconWrapper > Monthly Price: PKR{car.monthlyPrice}</div>
        </PricesSection>
}

{ isMobile ? 
<>  

  <div className="owner_information" style={{marginBlock:"1rem",borderTop:"1px solid #B1B3B4"}}>

  {data?.user ? 
  <>    

<h2 style={{fontSize:"18px",textAlign:"center",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem",marginBlock:'1rem'}}>Owner Information</h2>
          <div style={{display:"flex",justifyContent:"space-between",flexDirection:isSmallMobile ?"column":'row', alignItems:"flex-start", gap:".5rem"}}>   
            <img src={car.owner.photoLink} alt="Owner's Profile" style={{width:"50px",height:"50px",borderRadius:"8px"}} />
           <div className="section_content">   
            <div style={{fontSize:"14px",color:"#000",fontWeight:'300',fontFamily:"Poppins",lineHeight:"2rem"}}>Name: {car.owner.displayName}</div>
            <div style={{fontSize:"14px",color:"#000",fontWeight:'300',fontFamily:"Poppins",lineHeight:"2rem"}}>Email: {car.owner.email}</div>
            <div style={{fontSize:"14px",color:"#000",fontWeight:'300',fontFamily:"Poppins",lineHeight:"2rem"}}>Contact Number: {car.owner.contactNumber}</div>
            </div>
<div  style={{display:"flex",gap:".3rem"}}>   
<MdOutlineMessage color='#B1B3B4' style={{border:"1px solid #BDBDBD",padding:"5px",borderRadius:"8px"}} />
<MdOutlineCall  color='#B1B3B4' style={{border:"1px solid #BDBDBD",padding:"5px",borderRadius:"8px"}} />
</div>
            </div>


          <div className="booking_section" style={{marginBlock:".5rem", display:"flex",justifyContent:"space-between",paddingBlock:"10px", alignItems:"center", gap:".5rem",borderBlock:"1px solid #B1B3B4"}}>   
          
       
          
          
          </div>
          </>
          :
         
          <button onClick={google} className='book_button'  style={{borderRadius:"5px",padding:"12px",color:"#fff",border:"none",marginBottom:".6rem",cursor:"pointer",fontFamily:'poppins'}}>
          BOOK NOW
          </button>


  }
</div> 
</>
 : 
  
        <OwnerInfoSection id="ownerinfo">

{data?.user ?  <div className="owner-card">
            <h2>Owner Information</h2>
            <img src={car.owner.photoLink} alt="Owner's Profile" />
            <div>Name: {car.owner.displayName}</div>
            <div>Email: {car.owner.email}</div>
            <p>Contact Number: {car.owner.contactNumber}</p>
          
          </div>  :
               <button onClick={google} style={{backgroundColor:"#21408E",borderRadius:"5px",padding:"12px",color:"#fff",border:"none",marginBottom:".6rem",cursor:"pointer",fontFamily:'poppins'}}>
               BOOK NOW
               </button>


            }

         



        </OwnerInfoSection>

}


        <ReviewsContainer>
          <ReviewsSection>

            <Slider {...settings}>
           

            {reviews.map((review, index) => (
              <div key={index} className="review">
                <div className="review-header">
                  <span className="reviewer-name">{review.name}</span>
                  <span className="review-date">{review.date}</span>
                </div>
                <div className="review-content">{review.content}</div>
                <div className="review-rating">Rating: {'⭐'.repeat(review.rating)}</div>
              </div>
            ))}
                  </Slider>
          </ReviewsSection>

                      </ReviewsContainer>

          
      </BottomContainer>


    </CarDetailsWrapper>

  );
}
export default CarPage;
