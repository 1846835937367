import React from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { GET_NEAREST_CARS } from '../../services/carService/queries';
import CarDisplay from '../../components/CarDisplay';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ICarProps } from '../../components/car';

// Styled Components
const ResultsContainer = styled.div`
  ${tw`  
  flex flex-wrap justify-center items-center p-5 bg-gray-100 min-h-screen gap-1 md:gap-1`}`;

const LoadingMessage = styled.p`
  ${tw`text-xl text-blue-500`}
`;

const ErrorMessage = styled.p`
  ${tw`text-xl text-red-500`}
`;
const ResultsHeader = styled.p`
  ${tw`text-xl text-gray-700`}
  margin-top: 0; // Remove any top margin if present
  padding-top: 8rem; // Remove any top padding if present
  font-family:'poppins';
  text-align:center;
`;


const SearchResultsByLocation = () => {
  const location = useLocation();
  // Safely access coordinates with a fallback to prevent runtime errors if the state is not set
  const { coordinates, cityName } = location.state as { coordinates: { lat: number; lng: number }, cityName: string };

  const { loading, error, data } = useQuery(GET_NEAREST_CARS, {
    // Ensure coordinates are provided; otherwise, fallback to a default or skip the query
    variables: { latitude: coordinates?.lat || 0, longitude: coordinates?.lng || 0 },
    skip: !coordinates // Skip query execution if no coordinates are provided
  });

  if (loading) return <LoadingMessage>Loading...</LoadingMessage>;
  if (error) return <ErrorMessage>Error: {error.message}</ErrorMessage>;

  return (
    <ResultsContainer>

        <ResultsHeader>
        Showing search results for {cityName || "the selected area"}
        
</ResultsHeader>
      {data && data.nearestCars.map((car: ICarProps) => (
        <CarDisplay key={car.id} {...car} />
      ))}
    </ResultsContainer>
  );
};

export default SearchResultsByLocation;
