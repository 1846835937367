import React, { createContext, useContext, useState } from 'react';

// Define the shape of the context data
interface FormDataContextData {
  formData: Record<string, unknown>;
  updateFormData: (newData: Record<string, unknown>) => void;
}

// Create a new context with the correct type
const FormDataContext = createContext<FormDataContextData | undefined>(undefined);

interface FormDataProviderProps {
  children: React.ReactNode;
}

// Create a provider component
export const FormDataProvider: React.FC<FormDataProviderProps> = ({ children }) => {
  const [formData, setFormData] = useState<Record<string, unknown>>({});

  const updateFormData = (newData: Record<string, unknown>) => {
    setFormData(prevData => ({ ...prevData, ...newData }));
  };

  return (
    <FormDataContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormDataContext.Provider>
  );
};

// Create a custom hook for accessing the form data
export const useFormData = (): FormDataContextData => {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error('useFormData must be used within a FormDataProvider');
  }
  return context;
};

interface DisplayImagesProps {
  imageUrls: string[];
}

export const DisplayImages: React.FC<DisplayImagesProps> = ({ imageUrls }) => {
  return (
    <div>
      <h2>Uploaded Images:</h2>
      <ul>
        {imageUrls.map((url, index) => (
          <li key={index}>
            <img src={url} alt={`Uploaded Image ${index + 1}`} />
          </li>
        ))}
      </ul>
    </div>
  );
};