import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import backgroundImage from '../../../assets/404.png';

const StyledNotFoundPage = styled.div`
  position: relative;
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url(${backgroundImage}) no-repeat center center fixed;
    background-size: center.;
    opacity: 0.5;  // 50% opacity
    z-index: -1;  // Ensures the background is behind the content
  }

  h1 {
    font-size: 3.5rem;
    color: black;
  }

  p {
    font-size: 2.2rem;
    color: black;
  }

  a {
    margin-top: 20px;
    padding: 10px 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
`;

const NotFoundPage: React.FC = () => {
  return (
    <StyledNotFoundPage>
      <h1>404 - Page Not Found</h1>
      <p> Looks like you are lost </p>
      <Link to="/">Go to Homepage</Link>
    </StyledNotFoundPage>
  );
};

export default NotFoundPage;
