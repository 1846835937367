import React, { useState } from "react";
import { MdEmail } from "react-icons/md";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const ContactContainer = styled.div`
  ${tw`p-8 bg-white text-gray-700 shadow-lg rounded-lg mt-20`}
  animation: ${fadeIn} 0.5s ease-out;
`;

const Form = styled.form`
  ${tw`flex flex-col gap-4`}
`;

const Input = styled.input`
  ${tw`border p-3 rounded-md`}
  transition: border-color 0.3s;

  &:focus {
    ${tw`border-blue-500 outline-none`}
  }
`;

const TextArea = styled.textarea`
  ${tw`border p-3 rounded-md`}
  transition: border-color 0.3s;

  &:focus {
    ${tw`border-blue-500 outline-none`}
  }
`;

const Button = styled.button`
  ${tw`bg-blue-500 text-white p-3 rounded hover:bg-blue-700 transition-all`}
  cursor: pointer;
`;

const SocialMediaLinks = styled.div`
  ${tw`flex gap-6 justify-center mt-6`}
`;

const IconLink = styled.a`
  ${tw`text-3xl`}
  color: #555;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
`;

const Contact = () => {
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(true);
  };

  const closeModal = () => {
    setSuccess(false);
  };

  return (
    <ContactContainer>
      <h1 style={{ textAlign: "center", marginBottom: "20px", fontSize: "24px", fontWeight: "600" }}>Get in Touch</h1>
      <Form onSubmit={handleSubmit}>
        <Input type="text" placeholder="Your Name" required />
        <Input type="email" placeholder="Email Address" required />
        <TextArea placeholder="Your Message" rows="4" required />
        <Button type="submit">Submit</Button>
      </Form>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        Contact us directly at <MdEmail style={{ verticalAlign: "middle" }} /> info@hireacar.pk
      </div>
      <SocialMediaLinks>
        <IconLink href="https://www.facebook.com/hireacarpk" target="_blank" rel="noopener noreferrer">
          <FaFacebookF />
        </IconLink>
        <IconLink href="https://www.linkedin.com/company/hireacar-pk" target="_blank" rel="noopener noreferrer">
          <FaLinkedinIn />
        </IconLink>
        <IconLink href="https://www.instagram.com/hireacarpk" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </IconLink>
      </SocialMediaLinks>

      {success && (
        <div className="modal" style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "5px", textAlign: "center" }}>
            <h2>Success!</h2>
            <p>Your message has been sent successfully.</p>
            <button onClick={closeModal} style={{ padding: "10px 20px", backgroundColor: "#007BFF", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>
              OK
            </button>
          </div>
        </div>
      )}
    </ContactContainer>
  );
};

export default Contact;
