import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import lahore from "./../../../assets/img/lahore.png";
import Islamabad from "./../../../assets/img/islamabad.png";
import Quetta from "./../../../assets/img/quetta.png";
import Karachi from "./../../../assets/img/karachi.png";
import Peshawar from "./../../../assets/img/peshawar.png";
import multan from "./../../../assets/img/multan.png";
import muzaffarabad from "./../../../assets/img/muzaffarabad.png";
import gwadar from "./../../../assets/img/gwadar.png";
import jhelum from "./../../../assets/img/jhelum.png";

import { useNavigate } from 'react-router-dom';


import tw from "twin.macro";


const CarouselHeading = styled.h2`
  ${tw`
    text-left
    mt-0 // No top margin to attach it to the top of its container
    mb-16 // No bottom margin to control the height
    pl-2 // No padding-left to attach it to the left border
  `}
  font-family:'Copperplate', 'Poppins', sans-serif;
  font-weight: 400; // Increased weight for better visibility
  font-size: 1.25rem; // Increased font-size for visibility but maintaining the height requirement
  color: white;
  background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
  width: 40%;
  height: 2rem; // Set the height to 4rem
  line-height: 2rem; // Ensure the text is vertically centered in the 4rem height
  overflow: hidden; // Ensures the content does not exceed the specified height
  align-items: center; // Centers the text vertically
  justify-content: space-around; // Aligns the text to the start (left)
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 50%; // Allow the heading to be more responsive on smaller screens
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 40%; // Adjust width for medium screens
  }

  @media (min-width: 1025px) {
    width: 30%; // Fixed width for larger screens
  }
`;




const LocationBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
align-items:center;
`;

const CityButton = styled.div`
  width: 40%;
  margin: 10px;
  max-width: 100px;
  cursor: pointer;
`;

const CityImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  transition: transform 0.3s ease; /* Add transition effect for smooth hover */
  
  &:hover {
    transform: scale(1.05); /* Scale up slightly on hover */
  }
`;
const cities = [
  {
    name: "Lahore",
    imgSrc: lahore,
    alt: "Lahore",
    coordinates: { lat: 31.5497, lng: 74.3436 },

  },
  {
    name: "Islamabad",
    imgSrc: Islamabad,
    alt: "Islamabad",
    coordinates: { lat: 33.6844, lng: 73.0479},
  },
  {
    name: "Quetta",
    imgSrc: Quetta,
    alt: "Quetta",    
    coordinates: { lat: 30.1798 , lng: 66.9750 },

  },
  {
    name: "Karachi",
    imgSrc: Karachi,
    alt: "Karachi",
    coordinates: { lat: 24.8607 , lng: 67.0011 },

  },
  {
    name: "Peshawar",
    imgSrc: Peshawar,
    alt: "Peshawar",
    coordinates: { lat:34.0151 , lng: 71.5249 },

  },
  {
    name: "multan",
    imgSrc: multan,
    alt: "multan",
    coordinates: { lat: 30.1575, lng:71.5249 },

  },
  // {
  //   name: "faislabad",
  //   imgSrc: faislabad,
  //   alt: "faislabad",
  // },
  {
    name: "gwadar",
    imgSrc: gwadar,
    alt: "gwadar",    
    coordinates: {  lat: 25.1216 , lng:62.3254},

  },
  // {
  //   name: "gujranwala",
  //   imgSrc: gujranwala,
  //   alt: "gujranwala",
  // },
  {
    name: "muzaffarabad",
    imgSrc: muzaffarabad,
    alt: "muzaffarabad",
    coordinates: {  lat: 34.3700 , lng:73.4700},

  },
  {
    name: "jhelum",
    imgSrc: jhelum,
    alt: "jhelum",
    coordinates: { lat: 32.9345 , lng: 73.7310 },

  },
];
const ByLocation = () => {
  let navigate = useNavigate();

  return (
    <>
      <CarouselHeading>browse by city</CarouselHeading>
      <LocationBox>
        {cities.map((city, index) => (
          <CityButton
            key={index}
            onClick={() => navigate(`/cars/location/${city.name}`, { state: { coordinates: city.coordinates } })}
          >
            <CityImage src={city.imgSrc} alt={city.name} />
          </CityButton>
        ))}
      </LocationBox>
    </>
  );
};

export default ByLocation;