import gql from "graphql-tag";

export const ADD_NEW_CAR = gql`
mutation AddCars($newCarData: NewCarInput!) {
  addNewCar(
    newCarData: $newCarData
  ) {
    id
    name
    photos
    carType
    monthlyPrice
    dailyPrice
    hourlyPrice
    City
    year
    thumbnailUrl
    gearType
    color
   isAvailable
   owner{id}
   location{id}
  }
}
`;
export interface NewCarData {
  name: string;
  monthlyPrice: number;
  dailyPrice: number;
  carType: string;
  city: string;
  gearType: string;
  thumbnailUrl: string;
  photos: string[];
  year: number;
  color: string;
  features: string;
  isAvailable: boolean;
  owner: string;
  location: string;
}
export const ADD_NEW_CAR_MUTATION = gql`
  mutation AddNewCar($newCarData: NewCarInput!) {
    addNewCar(newCarData: $newCarData) {
      id
      name
    }
  }
`;

export const deleteCar = gql`
mutation deleteCar($id: String!) {
  deleteCar(id: $id)
}


`
export interface deleteData {
  id:string;
  deleteCar:boolean
}

export const UPDATE_CAR = gql`
  mutation UpdateCar($id: String!, $updateCarData: NewCarInput!) {
    updateCar(id: $id, updateCarData: $updateCarData) {
      id
       dailyPrice
      hourlyPrice
      monthlyPrice
     
      
     
    
      features
    
      isAvailable
     
      
      }
    }
  
`;
export interface UpdateCar {
  name: string;
  monthlyPrice: number;
  dailyPrice: number;
  carType: string;
  city: string;
  gearType: string;
  thumbnailUrl: string;
  photos: string[];
  year: number;
  color: string;
  features: string;
  isAvailable: boolean;
  owner: string;
  location: string;
}

