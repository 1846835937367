import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useFormData } from './formDataContext';
import tw from 'twin.macro';
import styled, { keyframes } from 'styled-components';
import CreatableSelect from 'react-select/creatable';


import { FaMapMarkerAlt } from 'react-icons/fa';
import axios from 'axios';
import { Car } from '../../components/car/index';
import { apolloClient as client } from "../../graphql";
import { ADD_NEW_CAR } from '../../services/carService/mutations';
import { useMutation } from "@apollo/client";
import { carType } from '../../icons/Cartype/Cartype';
import { ADD_NEW_LOCATION_MUTATION } from '../../services/locationService/mutation';
import { AddCars_addNewCar } from '../../services/carService/__generated__/AddCars';
import ImageUpload from '../../components/imageupload';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import {  CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};



const mobileMediaQuery = '@media (max-width: 768px)';

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeInDownContainer = styled.div`
  ${tw`
  
  flex
  flex-col
  
   
    bg-gray-200

  `}
  animation: ${fadeInDown} 0.8s ease forwards; /* Apply animation */
`;
const Container = styled.div`
  ${tw`
    py-6
   

  `}


`;

const MapContainer = styled.div`
  ${tw`
    w-full
    flex
    justify-start
    border
    border-gray-900
    rounded-md
    flex-grow
  `}
height:300px;
  ${mobileMediaQuery} {
    height: 300px; // Set a specific height for mobile devices (you can adjust this value as needed)
  }
`;


const SubmitButton = styled.input`
  ${tw`
    mt-6
    px-8
    py-2
    bg-blue-500
    text-white
    rounded-md
    transition-colors
    duration-300
    hover:bg-blue-600
    focus:outline-none
  `}
  background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
`;

const FormContainer = styled.div`
  ${tw`
    
   
    py-6
    px-6
    border
    border-gray-200
    rounded-md

  `}


`;


const SliderContainer = styled.div`
  ${tw`
    flex
    items-center
    mt-4
mb-4
  w-full
  `}
`;

const SliderLabel = styled.label`
  ${tw`
    mr-4
  `}
`;

const SliderValue = styled.span`
  ${tw`
    w-20
    text-right
  `}
`;

const LocationIcon = styled(FaMapMarkerAlt)`
  ${tw`
    text-gray-400
    mr-2
  `}
`;
const CarTypeContainer = styled.div`
  ${tw`
    flex  
    flex-col  
    mt-8
    mb-8
  `}
`;

const Label = styled.h3`
  ${tw`
  uppercase

  `}
`;
const MapLabel = styled.h1`
  ${tw`
  uppercase
  text-center
mb-8
  `}
`;


const ThumbnailImage = styled.img`
  ${tw`
    w-32
    h-24
    mr-2
    mb-2
    object-cover
  `}
`;
const CrossButton = styled.button`
  ${tw`
    absolute
    top-1
    right-3
    text-lg
    bg-[#f11900]
    cursor-pointer
    border-none
    text-gray-200
    font-bold
  `}
`;
const ThumbnailWrapper = styled.div`
  ${tw`
    flex
    flex-wrap
    gap-2
    mb-2
    bg-gray-400
        object-cover

  `}
`;

const ThumbnailContainer = styled.div`
  ${tw`
  h-24
    relative
    inline-block
    flex
    flex-col
    items-center
        object-cover
        bg-gray-700

  `}
`;

const MiscCont = styled.textarea`
  ${tw`
    w-full
    h-40
    px-4
    py-2
    border
    border-gray-300
    rounded-md
    resize-none
  `}
`;

// Mock data
const carBrands = ['Toyota Corolla', 'Toyota Camry', 'Toyota Hilux', 'Toyota Revo', 'Toyota Tundra', 'Toyota Yaris', 'Honda Accord', 'Honda Civic', 'Honda City', 'Honda BRV', 'Honda Cd 70', 'Honda CG 125', 'Suzuki Swift', 'Suzuki Cultus', 'Suzuki Liana', 'Suzuki Alto', 'BMW 1 Series', 'BMW 3 Series', 'BMW 5 Series', 'BMW 6 Series','Kia', 'Ford', 'MG', 'BMW', 'Mercedes', 'Porsche'];
const years = Array.from({ length: 22 }, (_, i) => new Date().getFullYear() - i); // last 22 years
const gas = ['Petrol', 'Diesel', 'Electric'];
// Convert the mock data to the format expected by react-select
const carBrandOptions = carBrands.map((brand) => ({ label: brand, value: brand }));
const yearOptions = years.map((year) => ({ label: String(year), value: year }));
const gasOptions = gas.map((gas) => ({ label: gas, value: gas }));
const transmissionOptions = [
  { label: 'Manual', value: 'Manual' },
  { label: 'Automatic', value: 'Automatic' },
];
const carTypeOptions = [
  { label: 'SUV', value: 'SUV' },
  { label: '4WD', value: '4WD' },
  { label: 'Saloon', value: 'Saloon' },
  { label: 'Hatchback', value: 'Hatchback' },
  { label: 'Coupe', value: 'Coupe' },
  { label: 'Sports', value: 'Sports' },
];

const carColorOptions = [
  { label: 'Black', value: 'Black' },
  { label: 'White', value: 'White' },
  { label: 'Silver', value: 'Silver' },
  { label: 'Blue', value: 'Blue' },
  { label: 'Red', value: 'Red' },
  { label: 'Green', value: 'Green' },
];


const driverOptions = [
  { label: 'With Driver', value: 'With Driver' },
  { label: 'Without Driver', value: 'Without Driver' },
];
interface Location {
  lat: number;
  lng: number;
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

type Option = {
  label: string,
  value: string,
};

type YearOption = {
  label: string,
  value: number,
};
interface AddCarFormProps {
  setCarData: React.Dispatch<React.SetStateAction<any>>;
  setcurrentFormNumber: React.Dispatch<React.SetStateAction<any>>;
  user: any;
  carData: any;
  currentFormNumber: any;
 
}


export const AddCarForm: React.FC<AddCarFormProps> = ({ user }) => {
  const { register, handleSubmit } = useForm();
  const [CarBrand, setCarBrand] = useState<Option | null>(null);
  const [Year, setYear] = useState<YearOption | null>(null);
  const [dailyPrice, setDailyPrice] = useState<number>(0);
  const [monthlyPrice, setMonthlyPrice] = useState<number>(0);
  const [hourlyPrice, setHourlyPrice] = useState<number>(0);
  const [Gas, setGas] = useState<Option | null>(null);
  const [gearType, setGearType] = useState<Option | null>(null);
  const [carType, setCarType] = useState<Option | null>(null);
  const [Color, setColor] = useState<Option | null>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<Option | null>(null);
  const [Driver, setDriver] = useState<Option | null>(null);
  const [Misctext, setMisctext] = useState('');
  const [Images, setImages] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const[userData, setUserData] = useState({
    id: "id",
  } as any);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 786px)' })
  const isSmallMobile = useMediaQuery({ query: '(max-width: 430px)' })
  const totalSteps = 4; // Total number of steps






  useEffect(() => {
    if (user) {
      console.log("Logged-on user's file ID:", user.id);
    }
  }, [user]);
  const [Location, setLocation] = useState<google.maps.LatLngLiteral | null>(null);
  const [City, setCity] = useState<Option | null>(null);
  const containerName = "carpictures"; // Replace with your Azure Blob Storage container name
  const sasToken = "?sp=racwdli&st=2024-04-30T04:14:42Z&se=2025-05-02T12:14:42Z&sv=2022-11-02&sr=c&sig=Gou1kUymMG%2Bq%2FudWWfVoDKoEdF%2FTNSbtYFGhBYJgAFo%3D";

  // Google Maps API Loader
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBwY6GpbILHBgLOeMpMraMWi20LvOSLZos"
  });
  const { updateFormData } = useFormData();
  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const selectedLocation = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      setLocation(selectedLocation);
      updateFormData({ Location: selectedLocation });

      // Extract latitude and longitude and print to console
      const latitude = selectedLocation.lat;
      const longitude = selectedLocation.lng;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }
  };

  // Function to handle form submission
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    client
      .mutate({
        mutation: ADD_NEW_LOCATION_MUTATION,
        variables: {
          newLocationData: {
            city: City?.value,
            latitude: Location?.lat || 0, 
            longitude: Location?.lng || 0,
          },
        },
      })
      .then((res) => {
        const locationId = res.data.addNewLocation.id;
        console.log("Location ID generated:", locationId);
        

        // Now that you have the locationId, proceed with the second mutation
        client
          .mutate({
            mutation: ADD_NEW_CAR,
            variables: {
              newCarData: {
                name: CarBrand?.value,
                monthlyPrice: monthlyPrice,
                dailyPrice: dailyPrice,
                carType: carType?.value,
                hourlyPrice: hourlyPrice,
                City: City?.value,
                gearType: gearType?.value,
                thumbnailUrl:
                  "https://img.indianautosblog.com/2015/10/2016-Honda-CIvic-white-front-quarter.jpg",
                photos: imageUrls,
                year: Year?.value,
                gas: Gas?.value,
                color: Color?.value,
                features: Misctext,
                isAvailable: true,
                owner: user.id, // Pass the owner ID as a string
                location: locationId, // Pass the location ID obtained from the first mutation
              },
            },
          })
          .then((res) => {
            console.log(res.data.addNewCar);
            toast.success('car add successfully')
            setIsSubmitted(true);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error in the second mutation:", error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error in the first mutation:", error);
        setIsLoading(false);
      });
  };
  const center = {
    lat: 33.6844,
    lng: 73.0479
  };
  // Function to handle map click event


  // Fetch city information based on selected Location using reverse geocoding
  useEffect(() => {
    if (Location) {
      fetchCityFromLocation(Location)
        .then((city) => {
          setCity({ label: city, value: city });
        })
        .catch((error) => {
          console.error('Error fetching city:', error);
        });
    }
  }, [Location]);

  // Function to fetch city from Location using reverse geocoding
  const fetchCityFromLocation = async (Location: google.maps.LatLngLiteral): Promise<string> => {
    try {
      const apiKey = 'AIzaSyBwY6GpbILHBgLOeMpMraMWi20LvOSLZos';
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Location.lat},${Location.lng}&key=${apiKey}`;
      const response = await axios.get(apiUrl);
      const results = response.data.results;
      if (results.length > 0) {
        const City = extractCityFromGeocodingResults(results[0]);
        return City;
      } else {
        throw new Error('Unable to fetch city from Location');
      }
    } catch (error) {
      throw new Error('Error fetching city from Location');
    }
  };

  // Function to extract city from geocoding results
  const extractCityFromGeocodingResults = (result: any): string => {
    const addressComponents = result.address_components;
    for (let i = 0; i < addressComponents.length; i++) {
      const component = addressComponents[i];
      const types = component.types;
      if (types.includes('locality')) {
        return component.long_name;
      }
    }
    throw new Error('City component not found in geocoding results');
  };

  // Function to handle city change
  const handleCityChange = (newValue: Option | null) => {
    setCity(newValue);
    setLocation(null); // Reset selected Location when a new city is selected from the dropdown

    if (newValue) {
      fetchLocationFromCity(newValue)
        .then((Location) => {
          setLocation(Location);
        })
        .catch((error) => {
          console.error('Error fetching Location:', error);
        });
    }
  };

  // Function to fetch Location from city
  function fetchLocationFromCity(newValue: Option | null): Promise<google.maps.LatLngLiteral> {
    return new Promise((resolve, reject) => {
      // Implement the logic to fetch Location based on city using a geocoding API
      // For example, you can use axios to send a request to the geocoding API
      // and resolve with the Location coordinates
      // Replace the placeholder code with your actual implementation
      const Location: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
      resolve(Location);
    });
  }
  const handleImageUrlsChange = (urls: string[]) => {
    setImageUrls(urls);
  };

  const handleCarBrandChange = (newValue: Option | null) => {
    setCarBrand(newValue);
  };

  const handleYearChange = (newValue: YearOption | null) => {
    setYear(newValue);
  };

  const handleDailySliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDailyPrice(Number(event.target.value));
  };

  const handleMonthlySliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonthlyPrice(Number(event.target.value));
  };
  const handleHourlySliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHourlyPrice(Number(event.target.value));
  };
  const handleGasChange = (newValue: Option | null) => {
    setGas(newValue);
  };

  const handleTransmissionChange = (newValue: Option | null) => {
    setGearType(newValue);
  };

  const handleCarTypeChange = (newValue: Option | null) => {
    setCarType(newValue);
  };

  const handleCarColorChange = (newValue: Option | null) => {
    setColor(newValue);
  };
  const handleDriverOptionChange = (newValue: Option | null) => {
    setDriver(newValue);
  };


 

const validateStep = (step: number): boolean => {
  switch (step) {
    case 0:
      return true;
    case 1:
      return  City !== null && Location?.lat !== null && Location?.lng !== null ;
       case 2:
      return  imageUrls.length > 0;
    case 3:
      return CarBrand !== null && Year !== null && Gas !== null && gearType !== null && carType !== null;
    case 4:
      return Color !== null && Driver !== null && hourlyPrice !== 0 && dailyPrice !== 0 && monthlyPrice !== 0 && Misctext !== '';
    default:
      return false;
  }
};


// Function to handle next button click
const handleNext = () => {
  // Validate current step before allowing to move to the next step
  const isValidStep = validateStep(currentStep);
  console.log('step',isValidStep)
  if (isValidStep) {
    setCurrentStep((prevStep) => prevStep + 1);
  } else {
    toast.error('Please fill in all required fields before proceeding.')
  }
};

// Function to handle previous button click
const handlePrevious = () => {
  setCurrentStep((prevStep) => prevStep - 1);
};





const progressPercentage = (currentStep / totalSteps) * 100;

const SuccessStep: React.FC = () => (
  <FadeInDownContainer>
    <h1>Thank you for submitting your car!</h1>
    <p>Your data is under review. You'll receive an email confirmation once your car is online.</p>
    <Link to="/UserProfile">
      <h4>Back to Profile</h4>
    </Link>
  </FadeInDownContainer>
);


  return (
    <Container> 

 

      <FormContainer>

      {!isSubmitted ? (
        <form onSubmit={onSubmit}> 

        
       
       
<>


{currentStep === 0 && (
<FadeInDownContainer >

<div style={{fontSize:"28px",color:"#000",fontWeight:'600',fontFamily:"Poppins",lineHeight:"2rem",marginBlock:isMobile?"2rem":"1rem",width:isMobile?isSmallMobile?"250px" :"327px":"100%"}}>It’s Easy to get
Started On Hire a Car</div>

<div style={{display:"flex",alignItems:"baseline",gap:"1rem",borderBottom:"1px solid #BDBDBD",paddingBottom:".8rem"}}>
<div className="left" style={{fontSize:"22px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2.1",}}>1</div>

<div className="Right">

  <div style={{fontSize:"22px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2.1",}}>Tell us about your car</div>
  <div style={{fontSize:"14px",color:"#000",fontWeight:'300',fontFamily:"Poppins",lineHeight:"2.1",width:"230px"}}>Share some basic information
such as where it is and how
many people can sit in it.</div>


</div>

</div>

<div style={{display:"flex",alignItems:"baseline",gap:"1rem",borderBottom:"1px solid #BDBDBD",paddingBottom:".8rem"}}>
<div className="left" style={{fontSize:"22px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2.1",}}>2</div>

<div className="Right">

  <div style={{fontSize:"22px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2.1",}}> Make it stand out </div>
  <div style={{fontSize:"14px",color:"#000",fontWeight:'300',fontFamily:"Poppins",lineHeight:"2.1",width:"230px"}}>Add 5 or more photos plus a
title and description - we’ll
help you out.</div>


</div>

</div>


<div style={{display:"flex",alignItems:"baseline",gap:"1rem",borderBottom:"1px solid #BDBDBD",paddingBottom:".8rem"}}>
<div className="left" style={{fontSize:"22px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2.1",}}>3</div>

<div className="Right">

  <div style={{fontSize:"22px",color:"#000",fontWeight:'500',fontFamily:"Poppins",lineHeight:"2.1",}}> Finish up and publish </div>
  <div style={{fontSize:"14px",color:"#000",fontWeight:'300',fontFamily:"Poppins",lineHeight:"2.1",width:"230px"}}>  Choose if you’d like to start, set a
daily price and publish.</div>


</div>

</div>

</FadeInDownContainer>
)}




{currentStep === 1 && (
<FadeInDownContainer >
<MapContainer>

<div style={{ width: '100%', height: '100%' }}>
<Label >Pick Your cars Location</Label>
{isLoaded ? (
<GoogleMap

mapContainerStyle={containerStyle}
center={center}
zoom={10}
onClick={handleMapClick}
>
{Location && <Marker position={Location} />}
</GoogleMap>
) : <div>Loading...</div>}
</div>
</MapContainer>




<div >
<Label className=" flex items-center" style={{marginTop:"2rem"}}>
<LocationIcon size={18} />
Location
</Label>
<Select
options={[
{ label: 'Karachi', value: 'Karachi' },
{ label: 'Lahore', value: 'Lahore' },
{ label: 'Islamabad', value: 'Islamabad' },
// Add more city options as needed
]}
value={City}
onChange={handleCityChange}
className="w-full"
/>
</div>




</FadeInDownContainer>
)}


{currentStep === 2 && (
<FadeInDownContainer>  
<Label > Add some photos of your car   </Label>




<ImageUpload onImageUrlsChange={handleImageUrlsChange} />
</FadeInDownContainer>
)}



{currentStep === 3 && (
        
       <FadeInDownContainer>   
<Label > Selct Your cars Information </Label>

       
            <div className="mb-4">
              <Label className="block">Car Brand</Label>
              <span className="text-red-800 text-sm ">Note : If the car brand is not available, please enter your custom brand name.</span>
              <CreatableSelect isClearable     options={carBrandOptions}
                onChange={handleCarBrandChange}
                className="w-full z-50"/>

              
              <input {...register('carBrand')} type="hidden" value={CarBrand?.value || ''} />
            </div>
       


          {CarBrand && (



            <div className="mb-4">
              <Label className="block">Model Year</Label>
              <Select
                options={yearOptions}
                onChange={handleYearChange}
                className="w-full"
              />


              
              <input {...register('modelYear')} type="hidden" value={Year ? String(Year.value) : ''} />
            </div>
          )}
          {Year && (
            <div className="mb-4">
              <Label className="block">Runs On</Label>
              <Select
                options={gasOptions}
                onChange={setGas}
                className="w-full"
              />
              <input {...register('gas')} type="hidden" value={Gas ? String(Gas.value) : ''} />
            </div>
          )}

          {Gas && (
            <div className="mb-4">
              <Label className="block">Transmission</Label>
              <Select
                options={transmissionOptions}
                value={gearType}
                onChange={setGearType}
                className="w-full"
              />
              <input {...register('transmission')} type="hidden" value={gearType?.value || ''} />
            </div>
          )}


          {gearType && (

            <div className='mt-44' >
              <CarTypeContainer>

                <Label className="block">Car Type</Label>

                {carTypeOptions.map((option) => (
                  <label key={option.value} className="inline-flex items-center">
                    <input
                      type="radio"
                      value={option.value}
                      checked={carType?.value === option.value}
                      onChange={() => handleCarTypeChange(option)}
                    />
                    <span className="ml-2">{option.label}</span>
                  </label>

                ))}

                <input {...register('carType')} type="hidden" value={carType?.value || ''} />
              </CarTypeContainer>

            </div>

          )}
          </FadeInDownContainer>

        )}


{currentStep === 4 && (


<FadeInDownContainer>  

<Label > Select Your cars Price </Label>
     
            <div className="mb-4">
              <Label className="block">Car Color</Label>
              <Select
                options={carColorOptions}
                value={Color}
                onChange={setColor}
                className="w-full"
              />
              <input {...register('carColor')} type="hidden" value={Color?.value || ''} />
            </div>
        

          {Color && (
            <div className="mb-4">
              <Label className="block">Driver Option</Label>
              <div style={{display:"flex",gap:"2rem",alignItems:"center"}}>
                {driverOptions.map((option) => (
                  <label key={option.value} className="inline-flex items-center" >
                    <input
                      type="radio"
                      value={option.value}
                      checked={Driver?.value === option.value}
                      onChange={() => handleDriverOptionChange(option)}
                    />
                    <span className="ml-2">{option.label}</span>
                  </label>
                ))}
              </div>
              <input {...register('driverOption')} type="hidden" value={Driver?.value || ''} />
            </div>
          )}
         

<div className="div" style={{display:"flex",justifyContent:"space-between",alignItems:"center",flexDirection:isMobile?'column':'row'}}>   

          {Color && (
            <div className="mb-4">
              <Label className="block">Hourly Price</Label>
              <SliderContainer>
                <input
                  type="range"
                  min={100}
                  max={10000}
                  step={100}
                  value={hourlyPrice}
                  onChange={handleHourlySliderChange}
                  className="w-full"
                />
                <SliderValue>{hourlyPrice} PKR</SliderValue>
              </SliderContainer>
            </div>)}
          {hourlyPrice && (
            <div className="mb-4">
              <Label className="block">Daily Price</Label>
              <SliderContainer>
                <input
                  type="range"
                  min={0}
                  max={100000}
                  step={100}
                  value={dailyPrice}
                  onChange={handleDailySliderChange}
                  className="w-full"
                />
                <SliderValue>{dailyPrice} PKR</SliderValue>
              </SliderContainer>
            </div>
          )}
          {dailyPrice && (
            <div className="mb-4">
              <Label className="block">Monthly Price</Label>

              <SliderContainer>

                <input
                  type="range"
                  min={''}
                  max={1000000}
                  step={100}
                  value={monthlyPrice}
                  onChange={handleMonthlySliderChange}
                  className="w-full"
                />
                <SliderValue>{monthlyPrice} PKR</SliderValue>

              </SliderContainer>
            </div>

          )}

</div>

          {monthlyPrice && (
            
            <div className="mb-4">
              <Label className="block">Description </Label>

              <textarea

                value={Misctext}
                onChange={(e) => setMisctext(e.target.value)}
                rows={6}
                style={{width:"100%",border:'1px solid gray',borderRadius:"10px"}}
              />

            </div>
          )}
           {monthlyPrice && (
            <button type="submit" style={{backgroundColor:"#21408E",borderRadius:"5px",color:"#fff",border:"none",marginBlock:".6rem",paddingInline:"25px",paddingBlock:"8px",fontSize:"16px",cursor:"pointer"}}> {isLoading ? (
              <div style={{display:"flex",gap:".7rem",justifyContent:"center",alignItems:"center"}}>
                <span style={{fontSize:"16px",color:"#fff",fontFamily:"poppins"}}>Submitting...</span>
                <ClipLoader color="#fff" size={16} />
              </div>
            ): (
           <span style={{fontFamily:"poppins"}}>   Submit </span> 

            )
            }
              
              
              </button>
          )}
   
</FadeInDownContainer>
)}





</>
     
<div style={{ border:"1px solid #BDBDBD",borderRadius: "6px",marginBlock:"1rem"  }}>  
  <div style={{width: `${progressPercentage}%`, borderRadius:"6px",backgroundColor: currentStep === totalSteps && monthlyPrice   ? 'green' : '#21408E', height: "10px", }} ></div>
</div>


       <div className="div" style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBlock:"1rem"}}>  
  {/* Show the "Previous" button if the current step is greater than 0 */}
  {currentStep > 0 &&   (
    <button onClick={handlePrevious} style={{backgroundColor:"#D9D9D9",borderRadius:"5px",padding:"12px",color:"gray",border:"none",marginBottom:".6rem",paddingInline:"25px",paddingBlock:"8px",fontSize:"16px",fontFamily:"poppins"}}>Previous</button>
  )}
  {/* Show the "Next" button if the current step is not the last step */}
  {currentStep < totalSteps  &&    (
    <button onClick={handleNext} style={{backgroundColor:"#21408E",borderRadius:"5px",color:"#fff",border:"none",marginBottom:".6rem",paddingInline:"25px",paddingBlock:"8px",fontSize:"16px",fontFamily:"poppins"}}>Next</button>
  )}
</div>


        </form>
): (
  // Render success step if form is submitted
  <SuccessStep />
)}
      </FormContainer>

  


    </Container>

  );



}
export default AddCarForm;
