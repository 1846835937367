import React, { useState } from 'react';
import AddCarForm from '../../containers/AddCarForm/index';
import { DisplayImages } from '../../containers/AddCarForm/formDataContext';
import { GrGallery } from "react-icons/gr";
import toast from 'react-hot-toast';

const accountName = 'hacblob';
const containerName = 'carpictures';
const blobEndpoint = 'https://hacblob.blob.core.windows.net/';
const sasToken ='?sp=racwdli&st=2024-04-30T04:14:42Z&se=2025-05-02T12:14:42Z&sv=2022-11-02&sr=c&sig=Gou1kUymMG%2Bq%2FudWWfVoDKoEdF%2FTNSbtYFGhBYJgAFo%3D'; // Replace this with the SAS token generated on the server-side.

const uploadImageToBlobStorage = async (file: File) => {
    try {
        const uniqueFileName = `${Date.now()}-${file.name}`;
        const urlWithSasToken = `${blobEndpoint}${containerName}/${uniqueFileName}${sasToken}`;

        // Manually determine the content type based on the file extension
        let contentType = 'image/jpeg'; // Default to JPEG type
        if (file.type === 'image/png' || file.name.endsWith('.png')) {
            contentType = 'image/png';
        }

        await fetch(urlWithSasToken, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': contentType,
                'x-ms-blob-type': 'BlockBlob', // Add this header
            },
        });

        // Return the actual image URL without SAS token
        const url = `${blobEndpoint}${containerName}/${uniqueFileName}`;
        return url;
    } catch (error) {
        console.error('Error uploading image:', error);
        return null;
    }
};

interface ImageUploadProps {
    onImageUrlsChange: (urls: string[]) => void;
}
const ImageUpload: React.FC<ImageUploadProps> = ({ onImageUrlsChange }) => {
    const [selectedImages, setSelectedImages] = useState<File[]>([]);
    const [uploadedImageUrls, setUploadedImageUrls] = useState<string[]>([]);

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            // Convert FileList to an array and append selected images to the existing array
            setSelectedImages(prevImages => [...prevImages, ...Array.from(files)]);
        }
    };
    
    const handleUploadPhotos = async () => {


        if (selectedImages.length === 0) {
            // Show toast message if no images are selected
            toast.error('Please select  image to upload.');
            return;
          }
        const urls = [];

        for (const image of selectedImages) {
            const imageUrl = await uploadImageToBlobStorage(image);
            if (imageUrl) {
                urls.push(imageUrl);
            }
        }
        // Update the state with the actual image URLs after all images are uploaded
        setUploadedImageUrls(urls);
        setSelectedImages([]); // Clear the selected images after uploading
        console.log([urls]);
        onImageUrlsChange(urls);

    };

    
    const handleRemoveImage = (index: number) => {
        setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
    };
    

    return (
        <div>


            <div style={{ display: "flex",flexDirection:"column", alignItems: "center", justifyContent: "center", border: "1px solid rgba(0, 0, 0, 0.31)", backgroundColor: "#EEECEC", width: "100%",height:"228px",gap:".6rem"  }}>
                <GrGallery size={59} style={{ marginRight: "10px" }} />
                <label htmlFor="file-upload" style={{ cursor: "pointer" }}> Choose 5 or more photos </label>

                <label htmlFor="file-upload" style={{ cursor: "pointer",marginTop:"1rem",fontFamily:"poppins" }}>      Upload from your device </label>
                <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    multiple // Allow selecting multiple images
                    onChange={handleImageUpload}
                    style={{ display: "none" }} // Hide the input element
                />
            </div>



            <div>
                {/* Display thumbnails of selected images */}
                {selectedImages.map((image, index) => (

<div key={index} style={{ position: 'relative', display: 'inline-block' }}>
<img
    src={URL.createObjectURL(image)}
    alt={`Thumbnail ${index}`}
    style={{ width: '150px', height: '150px', margin: '5px',objectFit:"fill" }}
/>
<button
    onClick={() => handleRemoveImage(index)}
    style={{ position: 'absolute', top: '5px', right: '5px', background: 'gray', border: 'none', cursor: 'pointer',color:"white" }}
>
    &#x2715;
</button>
</div>
                ))}
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>   
            <button type="button" onClick={handleUploadPhotos}  style={{backgroundColor:"#21408E",borderRadius:"5px",color:"#fff",border:"none",marginBlock:".6rem",paddingInline:"25px",paddingBlock:"8px",fontSize:"16px",cursor:"pointer",fontFamily:"poppins"}}>
                Upload Photos
            </button>
            </div>



            <div>
                {/* Display uploaded images */}
                {uploadedImageUrls.map((url, index) => (
                    <img
                        key={index}
                        src={url} // Use the URL directly from the state
                        alt={`Uploaded Image ${index}`}
                        style={{ width: '100px', height: '', margin: '5px' }}
                    />

                ))}
            </div>
        </div>
    );
};

export default ImageUpload;
