import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ChatState } from "../../../Context/ChatProvider";
import axios from "axios";
import toast from "react-hot-toast";

const StyledSearch = styled.div`
  ${tw`py-2 px-2 bg-gray-100 w-full`}
`;

const StyledInput = styled.input`
  ${tw` px-2 py-2 text-sm`}
`;

const StyledContact = styled.div`
  ${tw`bg-[#BDBDBD]  overflow-auto`}
`;

const StyledContactItem = styled.div`
  ${tw`bg-[#BDBDBD] px-3 py-3 flex items-center gap-4 hover:bg-gray-300 cursor-pointer `}
`;

const StyledImage = styled.img`
  ${tw`h-12 w-12 rounded-full`}
`;

const StyledMessageSection = styled.div`
  ${tw` flex-1 border-b border-gray-100 `}
`;

const StyledMessageText = styled.div`
  ${tw`text-gray-500 mt-1 text-sm`}
`;


const StyledSendIcon = styled.div`
  ${tw`fill-current`}
`;
const StyledMessageSender = styled.p`
  ${tw`text-sm mt-1`}
`;
const StyledSender = styled.div`
  ${tw`text-gray-700`}
`;

const StyledIcons = styled.div`
  ${tw`flex`}
`;

const StyledIcon = styled.div`
  ${tw`ml-4`}
`;

const StyledSvg = styled.svg`
  ${tw`fill-current`}
`;
const StyledHeader = styled.div`
  ${tw`py-2 px-3 pt-12 bg-gray-300 flex flex-row justify-between items-center`}
`;

const StyledTimestamp = styled.div`
  ${tw`text-xs text-gray-700`}
`;
export default function MyChat () {
    const { selectedChat, setSelectedChat, user, notification, setNotification } =
  ChatState();


//   const fetchChats = async () => {
//     // console.log(user._id);
//     try {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${user.token}`,
//         },
//       };

//       const { data } = await axios.get("/api/chat", config);
//       setChats(data);
//     } catch (error) {
//       toast({
//         title: "Error Occured!",
//         description: "Failed to Load the chats",
//         status: "error",
//         duration: 5000,
//         isClosable: true,
//         position: "bottom-left",
//       });
//     }
//   };





return(
    <>   
    <StyledHeader>
            <div>
              <StyledImage src="http://andressantibanez.com/res/avatar.png" />
            </div>
            <StyledIcons>
              <StyledIcon>
                <StyledSvg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{width:"24px",height:"24px"}}
                >
                  <path
                    fill="#727A7E"
                    d="M12 20.664a9.163 9.163 0 0 1-6.521-2.702.977.977 0 0 1 1.381-1.381 7.269 7.269 0 0 0 10.024.244.977.977 0 0 1 1.313 1.445A9.192 9.192 0 0 1 12 20.664zm7.965-6.112a.977.977 0 0 1-.944-1.229 7.26 7.26 0 0 0-4.8-8.804.977.977 0 0 1 .594-1.86 9.212 9.212 0 0 1 6.092 11.169.976.976 0 0 1-.942.724zm-16.025-.39a.977.977 0 0 1-.953-.769 9.21 9.21 0 0 1 6.626-10.86.975.975 0 1 1 .52 1.882l-.015.004a7.259 7.259 0 0 0-5.223 8.558.978.978 0 0 1-.955 1.185z"
                  />
                </StyledSvg>
              </StyledIcon>
              <StyledIcon>
                <StyledSvg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{width:"24px",height:"24px"}}

                >
                  <path
                    opacity=".55"
                    fill="#263238"
                    d="M19.005 3.175H4.674C3.642 3.175 3 3.789 3 4.821V21.02l3.544-3.514h12.461c1.033 0 2.064-1.06 2.064-2.093V4.821c-.001-1.032-1.032-1.646-2.064-1.646zm-4.989 9.869H7.041V11.1h6.975v1.944zm3-4H7.041V7.1h9.975v1.944z"
                  />
                </StyledSvg>
              </StyledIcon>
              <StyledIcon>
                <StyledSvg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{width:"24px",height:"24px"}}

                >
                  <path
                    fill="#263238"
                    fillOpacity=".6"
                    d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                  />
                </StyledSvg>
              </StyledIcon>
            </StyledIcons>
          </StyledHeader>
       
          <StyledSearch>
            <StyledInput type="text" placeholder="Search or start new chat" />
          </StyledSearch>
        
          <StyledContact>
            <StyledContactItem onClick={() => setSelectedChat(true)}>
              <StyledImage src="https://darrenjameseeley.files.wordpress.com/2014/09/expendables3.jpeg" />
              <StyledMessageSection>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <StyledSender>New Movie! Expendables 4</StyledSender>
                  <StyledTimestamp>12:45 pm</StyledTimestamp>
                </div>
                <StyledMessageText>Get Andrés on this movie ASAP!</StyledMessageText>
              </StyledMessageSection>
            </StyledContactItem>
  <StyledContactItem onClick={() => setSelectedChat(true)}>
              <StyledImage src="https://darrenjameseeley.files.wordpress.com/2014/09/expendables3.jpeg" />
              <StyledMessageSection>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <StyledSender>New Movie! Expendables 4</StyledSender>
                  <StyledTimestamp>12:45 pm</StyledTimestamp>
                </div>
                <StyledMessageText>Get Andrés on this movie ASAP!</StyledMessageText>
              </StyledMessageSection>
            </StyledContactItem>
              <StyledContactItem onClick={() => setSelectedChat(true)}>
              <StyledImage src="https://darrenjameseeley.files.wordpress.com/2014/09/expendables3.jpeg" />
              <StyledMessageSection>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <StyledSender>New Movie! Expendables 4</StyledSender>
                  <StyledTimestamp>12:45 pm</StyledTimestamp>
                </div>
                <StyledMessageText>Get Andrés on this movie ASAP!</StyledMessageText>
              </StyledMessageSection>
            </StyledContactItem>
              <StyledContactItem onClick={() => setSelectedChat(true)}>
              <StyledImage src="https://darrenjameseeley.files.wordpress.com/2014/09/expendables3.jpeg" />
              <StyledMessageSection>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <StyledSender>New Movie! Expendables 4</StyledSender>
                  <StyledTimestamp>12:45 pm</StyledTimestamp>
                </div>
                <StyledMessageText>Get Andrés on this movie ASAP!</StyledMessageText>
              </StyledMessageSection>
            </StyledContactItem>
              <StyledContactItem onClick={() => setSelectedChat(true)}>
              <StyledImage src="https://darrenjameseeley.files.wordpress.com/2014/09/expendables3.jpeg" />
              <StyledMessageSection>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <StyledSender>New Movie! Expendables 4</StyledSender>
                  <StyledTimestamp>12:45 pm</StyledTimestamp>
                </div>
                <StyledMessageText>Get Andrés on this movie ASAP!</StyledMessageText>
              </StyledMessageSection>
            </StyledContactItem>
              <StyledContactItem onClick={() => setSelectedChat(true)}>
              <StyledImage src="https://darrenjameseeley.files.wordpress.com/2014/09/expendables3.jpeg" />
              <StyledMessageSection>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <StyledSender>New Movie! Expendables 4</StyledSender>
                  <StyledTimestamp>12:45 pm</StyledTimestamp>
                </div>
                <StyledMessageText>Get Andrés on this movie ASAP!</StyledMessageText>
              </StyledMessageSection>
            </StyledContactItem>
              <StyledContactItem onClick={() => setSelectedChat(true)}>
              <StyledImage src="https://darrenjameseeley.files.wordpress.com/2014/09/expendables3.jpeg" />
              <StyledMessageSection>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <StyledSender>New Movie! Expendables 4</StyledSender>
                  <StyledTimestamp>12:45 pm</StyledTimestamp>
                </div>
                <StyledMessageText>Get Andrés on this movie ASAP!</StyledMessageText>
              </StyledMessageSection>
            </StyledContactItem>


            {/* More ContactItems go here */}
          </StyledContact> 
          </>
)
}

