import React from 'react';
import tw from 'twin.macro';

const Container = tw.div`

 
  flex 
  flex-col 
  items-start 
  justify-start 
  bg-gray-100
  bg-fixed
pl-10
pr-10
mb-10`;

const Heading = tw.h1`
  text-4xl 
  font-bold 
  text-gray-800 
  mb-0
  mt-24
`;

const Subheading = tw.h2`
  text-2xl 
  text-gray-600 
  mb-8
  mt-10
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <Heading>Privacy Policy   </Heading>
      <Subheading>Last Updated: [1 June 2023]</Subheading>
      <p>



This privacy policy ("Policy") outlines how we collect, use, share, and protect the personal information of users ("you" or "user") of our website [hireacar.pk] ("Site") and our services. By using our Site and services, you consent to the practices described in this Policy.

1.  Information We Collect

We collect the following information from you:</p><p>

1.1  Personal Information:
We collect your name, email address, phone number, mailing address, date of birth, gender, and driver's license number.
</p><p>
1.2  Payment Information:
We collect your credit card or other payment information when you make a reservation.
</p>
1.3  Device Information:
We collect information about the device you use to access the Site, such as your IP address, browser type, and operating system.
<p></p>

1.4 Usage Information:
We collect information about how you use the Site, such as the pages you visit, the links you click, and the search terms you use.
<p></p>

1.5  Location Information:
We collect information about your location when you use our services, such as your GPS coordinates.
<p></p>

1.6  Social Media Information:
We collect information from your social media accounts if you connect your social media accounts to our Site.
<p></p>

2.  How We Use Your Information

We use your information for the following purposes:
<p></p>

2.1  To provide you with the services you request:
This includes making a reservation or renting a car.
<p></p>

2.2  To communicate with you about your account or reservations.
<p></p>

2.3  To improve our services and provide you with a better experience.
<p></p>

2.4 To comply with our legal obligations.
<p></p>

2.5 To protect our rights and interests.
<p></p>

2.6 To market our services to you.
<p></p>

2.7 To conduct research and development.
<p></p>

2.8 To personalize your experience on our Site.
<p></p>

2.9 To provide you with customer support.
<p></p>

2.10 To prevent fraud and other illegal activities.
<p></p>
2.11 To comply with the terms of service for our Site.
<p></p>
3. Sharing Your Information
<p></p>
We may share your information with the following third parties:
<p></p>
3.1 Service providers:
We may share your information with service providers who help us operate the Site, such as payment processors and hosting companies.
<p></p>
3.2 Advertisers and marketing partners:
We may share your information with advertisers and marketing partners who help us promote our services.
<p></p>
3.3 Government agencies or other entities:
We may share your information with government agencies or other entities that require us to share your information in order to comply with the law.
<p></p>
3.4 Other companies within our corporate group:
We may share your information with other companies within our corporate group.
<p></p>
3.5  Third parties you authorize:
We may share your information with third parties that you authorize us to share your information with.
<p></p>
4. Your Rights

You have the following rights with respect to your personal information:
<p></p>
4.1  The right to access your personal information.
<p></p>
4.2  The right to correct any inaccuracies in your personal information.
<p></p>
4.3  The right to delete your personal information.
<p></p>
4.4  The right to object to the processing of your personal information.
<p></p>
4.5  The right to restrict the processing of your personal information.
<p></p>
4.6  The right to data portability.
<p></p>
4.7  The right to withdraw your consent to the processing of your personal information.

To exercise any of these rights, please contact us at [pp@hireacar.pk]
<p></p>
5.  Security

We take steps to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. These steps include:

5.1 Using secure servers and encryption technology.
<p></p>
5.2 Limiting access to your personal information to authorized employees.
<p></p>
5.3 Training our employees

 on the importance of privacy and security.

5.4 Requiring third parties who have access to your personal information to comply with our privacy standards.
<p></p>
6. Changes to This Privacy Policy

We may update this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will notify you by email or through a conspicuous notice on the Site.
<p>
7. Contact Us

If you have any questions about this Privacy Policy, please contact us at [info@hireacar.pk]

---</p>
    </Container>
  );
};

export default PrivacyPolicy;
