import React, { useState } from "react";
import styled from "styled-components";
import "tailwindcss/tailwind.css";

const AccordionWrapper = styled.div`
  border: 1px solid #e5e7eb;
`;

const AccordionItem = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #e5e7eb;
  }
`;

const AccordionTitle = styled.div`
  cursor: pointer;
  padding: 16px;
  background-color: #f3f4f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding: 16px;
`;

const Accordion: React.FC = () => {
    const [openSections, setOpenSections] = useState<{ [key: number]: boolean }>({});

    const toggleSection = (index: number) => {
        setOpenSections({ ...openSections, [index]: !openSections[index] });
    };

    const sections = [
        { title: "Browse and Compare Rates", content: "Navigate through our vast selection of cars and browse the different rates available. Take your time to analyze and compare the options to find the one that fits your budget and requirements." },
        { title: "Contact the Car Owner", content: "Once you're satisfied with the rates, call the car owner using the information provided on the listing. Discuss and set the rental terms directly with them to ensure a smooth and agreeable transaction." },
        { title: "Prepare Required Documents", content: "As a renter, you'll need to provide all the essential papers requested by the car owner, including proof of identity. This ensures transparency and trust in the renting process." },
        { title: "Driving License Requirements", content: " If you're hiring the car without a driver, make sure that the person making the booking has a valid driving license. Only the person who booked the car is allowed to drive, so ensure compliance with this rule." },
        { title: "Liability and Damages:", content: "The renter will be liable for any damages or mishaps that might occur during the rental period without a driver. However, if the car is hired with a driver, the car owner assumes responsibility for any damages." },
        { title: "Understanding Our Role", content: "Hireacar is a marketplace that connects buyers and sellers. We are not responsible for any damages, liabilities, mishaps, or mishandling by either the driver or the renter. Our platform facilitates the connection, but the responsibility lies with the parties involved." },
        { title: "Leave a Review", content: "Once you're satisfied with the rates, call the car owner using the information provided on the listing. Discuss and set the rental terms directly with them to ensure a smooth and agreeable transaction." },
        { title: "Need Assistance?", content: "If you have any questions or need help with the booking process, our support team is here to assist you. Don’t hesitate to reach out!" },

        // Add other sections here...
    ];

    return (
        <AccordionWrapper>
            {sections.map((section, index) => (
                <AccordionItem key={index}>
                    <AccordionTitle className="text-lg font-semibold" onClick={() => toggleSection(index)}>
                        {section.title}
                        <span>{openSections[index] ? "-" : "+"}</span>
                    </AccordionTitle>
                    <AccordionContent className="text-base" isOpen={openSections[index]}>
                        {section.content}
                    </AccordionContent>
                </AccordionItem>
            ))}
        </AccordionWrapper>
    );
};

export default Accordion;
