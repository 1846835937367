import React from "react";
import { Vector } from "../../icons/Vector";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { useMediaQuery } from "react-responsive";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa"; // Importing icons
const FooterContainer = styled.div`
  ${tw`
    w-full
    flex
    justify-around 
    items-center
    bg-[#ECEEF4]
    py-8
  `}
  font-family: Cabin;
`;

const ContactInfo = styled.div`
  ${tw`
    pr-6
  `}
`;

const LinksContainer = styled.div`
  ${tw`
    flex
    flex-col
    gap-2
  `}
`;

const LinkItem = styled(Link)`
  ${tw`
    
  `}
  cursor: pointer;
`;

const LogoTaglineContainer = styled.div`
  ${tw`
    mt-4
    md:mt-0
    p-0
  `}
`;

const SocialIconsContainer = styled.div`
  ${tw`
    flex
    items-center
    gap-4
  `}
  font-size: 24px; // Adjust icon size here
`;

// Styling for the icons
const FacebookIcon = styled(FaFacebookF)`
  color: #3b5998; // Facebook brand color
`;

const LinkedInIcon = styled(FaLinkedinIn)`
  color: #0e76a8; // LinkedIn brand color
`;

const InstagramIcon = styled(FaInstagram)`
  color: #e1306c; // Instagram brand color (simplified)
`;

interface Props {
  className: any;
}

export const Footer = ({ className }: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 786px)' });

  return (
    <>
      {isMobile ? (
        <div className="footer_section" style={{display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "center", padding: "20px", backgroundColor: "#ECEEF4"}}>
          <div className="logo_Section">
            <Vector className="vector-instance" />
          </div>
          <div style={{fontSize: "15px", color: "#000", fontWeight: '500', fontFamily: "Poppins", opacity: "0.6", marginBlock: "1rem"}}>
            Rides for the daily achievers
          </div>
          <div style={{display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "center"}}>
            <div style={{fontSize: "18px", color: "#000", fontWeight: '500', fontFamily: "Poppins", textDecoration: "none"}}> Company </div>
            <Link to="/TermsandConditions" style={{fontSize: "15px", color: "#000", fontWeight: '500', fontFamily: "Poppins", opacity: "0.6", textDecoration: "none"}}>Terms and Conditions</Link>
            <Link to="/privacypolicy" style={{fontSize: "15px", color: "#000", fontWeight: '500', fontFamily: "Poppins", opacity: "0.6", textDecoration: "none"}}>Privacy Policy</Link>
            <Link to="/Contact" style={{fontSize: "15px", color: "#000", fontWeight: '500', fontFamily: "Poppins", opacity: "0.6", textDecoration: "none"}}>Support</Link>
          </div>
          <div>
            <div style={{fontSize: "18px", color: "#000", fontWeight: '500', fontFamily: "Poppins", textDecoration: "none",}}>Contact Us</div>
            <div style={{fontSize: "15px", color: "#000", fontWeight: '500', fontFamily: "Poppins", opacity: "0.6", textDecoration: "none", marginBlock: "10px"}}>info@hireacar.pk</div>
    
          </div>
          <div style={{fontSize: "13px", color: "#000", fontWeight: '700', fontFamily: "Poppins", opacity: "0.6", textDecoration: "bold", marginBlock: "1rem"}}>
            © Copyright Hireacar pvt(ltd).
          </div>
        </div>
      ) : (
        <FooterContainer>
          <LogoTaglineContainer>
            <Vector className="vector-instance" />
            <p style={{fontSize: "14px", color: "#000", fontWeight: '500', fontFamily: "Poppins", opacity: "0.6", width: "200px"}}>Rides for the daily achievers</p>
          </LogoTaglineContainer>
          <LinksContainer>
            <div style={{fontSize: "18px", color: "#000", fontWeight: '500', fontFamily: "Poppins", textDecoration: "none"}}> Company </div>
            <Link to="/TermsandConditions" onClick={() => window.scrollTo(0, 0)} style={{fontSize: "14px", color: "#000", fontWeight: '500', fontFamily: "Poppins", textDecoration: "none", opacity: "0.6"}}>Terms and Conditions</Link>
            <Link to="/privacypolicy" onClick={() => window.scroll(0, 0)} style={{fontSize: "14px", color: "#000", fontWeight: '500', fontFamily: "Poppins", textDecoration: "none", opacity: "0.6"}}>Privacy Policy</Link>
            <Link to="/Contact" onClick={() => window.scroll(0, 0)} style={{fontSize: "14px", color: "#000", fontWeight: '500', fontFamily: "Poppins", textDecoration: "none", opacity: "0.6"}}>Support</Link>
          </LinksContainer>
          <ContactInfo>
            <div style={{fontSize: "18px", color: "#000", fontWeight: '500', fontFamily: "Poppins", textDecoration: "none"}}>Contact Us</div>
            <div style={{fontSize: "14px", opacity: "0.6", color: "#000", fontWeight: '500', fontFamily: "Poppins", textDecoration: "none", marginBlock: "10px"}}>info@hireacar.pk</div>


          </ContactInfo>
        </FooterContainer>
      )}
    </>
  );
};

export default Footer;
