import React from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import CarMini from './../../components/CarMini';
import { GET_NEAREST_CARS } from './../../services/carService/queries';
import { ICarProps } from '../../components/car';
import styled from 'styled-components';
import tw from 'twin.macro';
import CarDisplay from '../../components/CarDisplay';

// Styled Components


const ResultsContainer = styled.div`
  ${tw`  
  flex flex-wrap justify-center items-center p-5 bg-gray-100 min-h-screen gap-1 md:gap-1`}`;

const LoadingMessage = styled.p`
  ${tw`text-xl text-blue-500`}
`;

const ErrorMessage = styled.p`
  ${tw`text-xl text-red-500`}
`;
const ResultsHeader = styled.p`
  ${tw`py-2 text-xl text-gray-700`}
  margin-top: 0; // Remove any top margin if present
  padding-top: 8rem; // Remove any top padding if present
`;


// SearchResults component
const SearchResults = () => {
  const location = useLocation();
  const { selectedLatitude, selectedLongitude, searchQuery } = location.state || { selectedLatitude: 0, selectedLongitude: 0, searchQuery: "" };

  const { loading, error, data } = useQuery(GET_NEAREST_CARS, {
    variables: { latitude: selectedLatitude, longitude: selectedLongitude },
  });

  if (loading) return <LoadingMessage>Loading...</LoadingMessage>;
  if (error) return <ErrorMessage>Error: {error.message}</ErrorMessage>;

  return (
    <div>
      <ResultsHeader>
        Showing search results for {searchQuery ? `"${searchQuery}"` : "the selected area"}
      </ResultsHeader>
      <ResultsContainer>
        {data && data.nearestCars && data.nearestCars.map((car: ICarProps) => (
          <CarDisplay key={car.id} {...car} />
        ))}
      </ResultsContainer>
    </div>
  );
};

export default SearchResults;