import gql from 'graphql-tag';

export const CREATE_USER_MUTATION = gql`

mutation CreateUser($newUserData: NewUserInput!) {
  createUser(newUserData: $newUserData) {
      id
      displayName
      email
      googleId
      photoLink
      contactNumber
     isVerified
      cnic
  }
}
`;


export const GET_USER_QUERY_BY_GOOGLE_ID = gql`
query userByGoogleId($id: String!) {
  userByGoogleId(googleId: $id) {
      id
      googleId
      photoLink
      isVerified
      displayName
      email
      contactNumber
      cnic
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query GetUserProfile($id: String!) {
    user(id: $id) { 
      id
      googleId
      displayName
      email
      cnic
      contactNumber
      isVerified
      photoLink
      cars {
        id
      }
      bookings {
        id
      }
      reviews {
        id
      }
    }
  }
`;



export const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $user: NewUserInput!) {
    updateUser(id: $id, user: $user) {
      id
      googleId
      displayName
      email
      photoLink
      contactNumber
      isVerified
      cnic
      # other car fields
      # cars {
      #   id
      # }
      # other booking fields
      # bookings {
      #   id
      # }
      # # other review fields
      # reviews {
      #   id
      # }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;



export interface UpdatedUserData {
  googleId: string;
  displayName: string;
  email: string;
  photoLink: string;
  contactNumber: string;
  isVerified: boolean;
  cnic: string;
}



// export const UPDATE_USER_MUTATION = gql`
//   mutation UpdateUser($id: String!, $user: NewUserInput!) {
//     updateUser(id: $id, user: $user) {
//       id
//       googleId
//       displayName
//       email
//       photoLink
//       contactNumber
//       isVerified
//       cnic
//     }
//   }
// `;


export const GET_USER_QUERY = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      googleId
      photoLink
      isVerified
      displayName
      email
      contactNumber
      cnic
    }
  }
`;




// export const GET_USER = gql`
// query GetUser($Id: String!) {
//   user(Id: $Id) {
//     googleId
//     displayName
//     email
//     photoLink
//     contactNumber
//     isVerified
//     cnic
//     cars {
//       id
//     }
//     bookings {
//       id
//     }
//     reviews {
//       id
//     }
//   }
// }
// `;
