import React, { useState, useEffect, ReactElement } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import debounce from 'lodash.debounce';
import _ from 'lodash';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useLazyQuery } from '@apollo/client';
import { GET_NEAREST_CARS } from '../../services/carService/queries';

// Styled component
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Styled components


const SearchAreaContainer = styled.div`
  ${tw`flex flex-col items-center justify-center w-full pb-4 px-2 relative`} // Add relative positioning
`;

const SearchBarContainer = styled.div`
  ${tw`flex items-end w-full max-w-2xl`}
  @media (max-width: 768px) {
    ${tw`w-80`}
  }
`;


const SearchInput = styled.input`
  ${tw`flex-grow text-black h-12 px-4 rounded-l-md border-none focus:outline-none focus:border-none`}
  font-family: poppins;

`;
// Styled components

  

// const SearchAreaContainer = styled.div`
//   ${tw`flex flex-col items-center justify-center rounded-lg shadow-lg`}
//   background: white; // Adjust the background color as needed
//   padding: 20px; // Adjust padding as needed
//   margin: auto; // Center the container
//   width: 80%; // Adjust width as needed

//   @media (max-width: 768px) {
//     ${tw`w-full`}
//   }
// `;

const SearchInputGroup = styled.div`
  ${
  tw`
    relative
    flex
    flex-col
    items-center
    justify-center
    border
    border-gray-500
    rounded-md
    md:mt-10
    w-60
    md:w-60
    md:h-40
  `}
`;
const SuggestionsList = styled.div`
  ${tw`
    absolute
    w-full
    mt-0
    border

    border-gray-300
    bg-white
    rounded-md
    shadow-lg
    z-50
  `}
  top: 100%;
  left: 0;
  z-index: 10; // Adjust as needed
`;

const SuggestionItem = styled.div`
  ${tw`
    py-2
    px-4
    text-sm
    text-gray-700
    hover:bg-gray-100
    cursor-pointer
  `}
`;

const SearchButton = styled.button`
  ${tw`flex items-center justify-center w-12 h-[3.1rem] bg-blue-500 text-white rounded-r-lg border-none`}
  ;
 background: #1F4590 `;

const debouncedFetchSuggestions = _.debounce(async (input, setSuggestionsCallback) => {
  if (!input) {
    setSuggestionsCallback([]);
    return;
  }

  try {
    const response = await fetch(`http://api.hireacar.pk/location-suggestions?input=${encodeURIComponent(input)}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setSuggestionsCallback(data.predictions.map((prediction: { place_id: string; description: string; }) => ({
      id: prediction.place_id,
      name: prediction.description,
    })));
  } catch (error) {
    console.error('Error fetching location suggestions:', error);
    setSuggestionsCallback([]); // Clear suggestions on error
  }
}, 300);

type Suggestion = {
  id: string;
  name: string;
};

// Main component
export function SearchArea() {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number, lng: number } | null>(null);
  const [getNearestLocations, { data, loading, error }] = useLazyQuery(GET_NEAREST_CARS);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBwY6GpbILHBgLOeMpMraMWi20LvOSLZos', // Replace with your actual API key
    libraries: ['places'],
  });

  useEffect(() => {
    // Cleanup the debounce on component unmount
    return () => {
      debouncedFetchSuggestions.cancel();
    };
  }, []);
  const fetchSuggestions = _.debounce(async (input) => {
    // Ensure input is not empty
    if (!input) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(`https://api.hireacar.pk/location-suggestions?input=${encodeURIComponent(input)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSuggestions(data.predictions.map((prediction: { place_id: string; description: string; }) => ({
        id: prediction.place_id,
        name: prediction.description,
      })));
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
      setSuggestions([]); // Clear suggestions on error
    }
  }, 300);

  const navigate = useNavigate();

  const handleSearch = async () => {
    if (!searchQuery) {
      console.error("Search query is empty");
      return;
    }

    try {
      const results = await getGeocode({ address: searchQuery });
      const { lat, lng } = await getLatLng(results[0]);

      if (lat == null || lng == null) {
        console.error("Invalid location data received");
        return;
      }

      // Navigate to the search results page with the necessary state
      navigate('/search-results', {
        state: {
          selectedLatitude: lat,
          selectedLongitude: lng,
          searchQuery: searchQuery  // Include searchQuery in the state
        }
      });

      setSelectedLocation({ lat, lng });
      getNearestLocations({ variables: { latitude: lat, longitude: lng } });
    } catch (error) {
      console.error("Error in handleSearch: ", error);
    }
  };


    if (!isLoaded) return <div>Loading...</div>;

  return (
    <SearchAreaContainer>

                <SearchBarContainer>

      <SearchInput
        type="text"
        placeholder="Search location"
        
        value={searchQuery}
        onChange={(event) => {
          setSearchQuery(event.target.value);
          if (event.target.value.trim() === '') {
            setSuggestions([]);
            fetchSuggestions.cancel();
          } else {
            fetchSuggestions(event.target.value);
          }
        }}
      />

      {suggestions.length > 0 && (
        <SuggestionsList>
          {suggestions.map((suggestion) => (
            <SuggestionItem
              key={suggestion.id}
              onClick={() => {
                setSearchQuery(suggestion.name);
                setSuggestions([]);
              }}
            >
              {suggestion.name}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
        <SearchButton onClick={handleSearch}>
                   <FontAwesomeIcon icon={faSearch} />
                   </SearchButton>

                </SearchBarContainer>

              </SearchAreaContainer >


  );
}

export default SearchArea;
//       return (
//         <SearchAreaContainer>
//             <SearchInput type="text" placeholder="Search location" />
//             <SearchButton>
//               <FontAwesomeIcon icon={faSearch} />
//             </SearchButton>
//           </SearchBarContainer>
//         </SearchAreaContainer>
//       );
//     };
  
//   export default SearchArea;
