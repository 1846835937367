import React, { useEffect, useState } from 'react';
import AddCarForm from './index';
import UserProfileForm from './Updateuserform';
import { apolloClient as client } from '../../graphql';
import { GET_USER_QUERY, GET_USER_QUERY_BY_GOOGLE_ID } from '../../services/userService/queries';
import { FormDataProvider } from './formDataContext';
import UserProfile from '../UserProfile';
import styled, { keyframes } from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeInDownContainer = styled.div`
  ${tw`
    w-full
   py-6
    bg-gray-200
   
  `}
  animation: ${fadeInDown} 0.8s ease forwards; /* Apply animation */
`;



interface FormProps {
  userId: string;
}

const totalForms = 3;

export const MainForm: React.FC<FormProps> = ({ userId }) => {
  const [currentFormNumber, setcurrentFormNumber] = useState(0);
  const [userData, setUserData] = useState({
    id: "id",
    googleId: userId,
    photoLink: "photoLink",
    isVerified: true,
    displayName: "displayName",
    email: "email",
    cnic: "cnic",
    contactNumber: "contactNumber"
  } as any);

  const [carData, setCarData] = useState({
    name: "name",
    monthlyPrice: "monthlyPrice",
    dailyPrice: "dailyPrice",
    CarType: "CarType",
    City: "city",
    gearType: "gearType",
    thumbnailUrl: "thumbnailUrl",
    photos: ["photos"],
    year: 2012,
    color: "color",
    features: "features",
    isAvailable: false,
    owner: parseInt(userData?.id),
    location: 1
  } as any);

  useEffect(() => {
    client.query({
      query: GET_USER_QUERY_BY_GOOGLE_ID,
      variables: {
        id: userId
      }
    }).then((res) => {
      setUserData(res.data.userByGoogleId);
    }).catch((err) => {
      console.log(err)
      console.log(JSON.stringify(err, null, 2));
    });
  }, [userId]);

 

 

  return (
    <FormDataProvider>
      <FadeInDownContainer>
      <AddCarForm
        setCarData={setCarData}
        setcurrentFormNumber={setcurrentFormNumber}
        user={userData} // Pass the userData object as a prop
        carData={carData}
        currentFormNumber={currentFormNumber}
       
      />
      </FadeInDownContainer>
     
    
    </FormDataProvider>
  );
}

export default MainForm;
