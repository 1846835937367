import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Marginer } from "../../components/marginer";
import { TopCarousel } from "./topCarousel";

import { MidCarousel } from "./midCarousel";
import WhyareweDifferent from "../../components/WhyareweDifferent";
import ByLocation  from "../../components/bylocation";
import Testcomp from "../../components/testcomp";
import { Toggle } from "../../components/Toggle";
import Bybrand from "../../components/bybrand";




interface IPageContainerProps {
  margin: string;
}
const PageContainer = styled.div<IPageContainerProps>`
  ${tw`
    
    flex-col
    w-full
    bg-gray-300
  `}

  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */

  @media (max-width: 768px) {
    margin: 0;
    padding: 0 0em;
    justify-content: space-around;
    align-items: center;
  
  
  
  }
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
`;

function HomePage() {
  return (
    <>     
    <PageContainer margin="0 0">

      <Testcomp />
      <TopCarousel />
      <MidCarousel  />
      <ByLocation />
      <Bybrand/>
      <WhyareweDifferent />
      <Marginer direction="vertical" margin={['2em', '4em']} />
    
    </PageContainer>
      </>
  );
}

export default HomePage;
