import React from "react";
import Vector from "./../../../assets/img/vector.png";
import Vector1 from "./../../../assets/img/shield.png"
import Vector2 from "./../../../assets/img/Vector2.png"
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import tw from "twin.macro";
import { Carousel } from 'react-responsive-carousel';

const CarouselContainer = styled.div`
  ${tw`
    flex
    justify-center
    items-center
    overflow-hidden
  `}
  max-width: 100%; // Ensure the container does not overflow the screen width
  padding: 2rem 0; // Add some vertical spacing
`;
const Textheading = styled.h2`
  ${tw`
    text-left
    mt-10 // No top margin to attach it to the top of its container
    mb-12 // No bottom margin to control the height
    pl-2 // No padding-left to attach it to the left border
  `}
  font-family:'Copperplate', 'Poppins', sans-serif;
  font-weight: 400; // Increased weight for better visibility
  font-size: 1.25rem; // Increased font-size for visibility but maintaining the height requirement
  color: white;
  background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
  width: 40%;
  height: 2rem; // Set the height to 4rem
  line-height: 2rem; // Ensure the text is vertically centered in the 4rem height
  overflow: hidden; // Ensures the content does not exceed the specified height
  align-items: center; // Centers the text vertically
  justify-content: space-around; // Aligns the text to the start (left)
  border-bottom-right-radius: 2rem;
border-top-right-radius: 2rem;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 50%; // Allow the heading to be more responsive on smaller screens
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 40%; // Adjust width for medium screens
  }

  @media (min-width: 1025px) {
    width: 30%; // Fixed width for larger screens
  }
`;
const CardContainer = styled.div`
  ${tw`
    flex
    flex-col
    items-center
    justify-center
    m-4 // Adjust margin as needed
  `}
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; // Take full width of the Carousel slide
  max-width: 300px; // Or adjust as needed
  margin: 0 auto; // Center within the Carousel slide
  align-self: center; // Center the card within its flex container
font-family:'poppins','copperplate',calibri
`;

const CardImage = styled.img`
  max-width: 100%; // Ensure the image scales down if necessary
  border-radius: 1rem; // Consistent with CardContainer
`;

const CardHeading = styled.div`
  ${tw`
    text-xl
    font-semibold
    mt-4 // Top margin
  `}
  color: #000; // Heading color
`;

const CardDescription = styled.div`
  ${tw`
    text-sm
    mt-2 // Top margin
  `}
  line-height: 1.5rem; // Improved readability
`;



const settings = {
  showArrows: true,
  autoPlay: true,
  interval: 3000,
  infiniteLoop: true,
  showThumbs: false,
  showStatus: false,
  useKeyboardArrows: true,
  swipeable: true,
  dynamicHeight: false,
  showIndicators: false // Add this line to hide the dots

};

interface CardProps {
  image: string;
  description: string;
  heading: string;
}


const Card = ({ image, description, heading }: CardProps) => {
  return (
    <CardContainer>
      <CardImage src={image} alt="Card" />
      <CardHeading>{heading}</CardHeading>
      <CardDescription>{description}</CardDescription>
    </CardContainer>
  );
};


const WhyareweDifferent = () => {
  
  return (
    <div >
      <Textheading > Why us  </Textheading>
      <CardContainer> 

      <Carousel {...settings}>

        <Card image={Vector} heading="Earn Effortlessly, Rent with Confidence" description=""/>
        <Card image={Vector1} heading="Your Car, Your Choice - For Every Occasion" description="" />
        <Card image={Vector2} heading="Building Trust in Every Trip"  description="" />
      </Carousel>
      </CardContainer> 

    </div>
  );
};

export default WhyareweDifferent;
