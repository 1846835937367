import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from '@apollo/client';
import styled from "styled-components";
import tw from "twin.macro";
import { GET_USER_PROFILE } from "../../services/userService/queries";
import { Car } from "../../components/car";
import { GetCarById } from "../../services/carService/__generated__/GetCarById";
import { GET_CAR_BY_ID } from "../../services/carService/queries";
import { ICarProps } from '../../components/car/index';
import topCars, { TopCars } from "../HomePage/topCars";
import CarMini from "../../components/CarMini";
import { useMediaQuery } from "react-responsive";
import { MdOutlineCall ,MdOutlineModeEditOutline, } from "react-icons/md";
import { IoArrowForwardSharp } from "react-icons/io5";
import Slider from 'react-slick';
import './User.css'
import { CREATE_USER_MUTATION, UPDATE_USER } from "../../services/userService/queries";
import { apolloClient as client } from "../../graphql";
import { carType as CarTypeIcon } from "../../icons/Cartype";



import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { faL } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import CarDropdown from "../../components/CarEditDropdown/Index";

const Container = styled.div`
  ${tw`
  py-20
    min-h-screen
    bg-gray-100
    p-2
    justify-center
  `}
`;

const BoxProfileContainer = styled.div`
  ${tw`
  flex
  
    shadow-sm
    justify-center
px-6 py-4
  `}
   
width:'80%';

`;

const BoxinfoContainer = styled.div`
  ${tw`
  flex
  
    shadow-sm
    justify-center
px-6
py-4
`}
    border: 1px solid #B1B3B4;
border-radius:1rem;
width:'100%';

`;



const ProfileContainer = styled.div`
  ${tw`
    flex
    flex-col
    justify-center
    items-center
    mt-10
    p-4
  `}

`;

const CarsContainer = styled.div`
  ${tw`
    
    
       mt-5
    p-4
  `}

`;


const Input = styled.input`
  ${tw`
    border-none
   text-[16px] 
sm:text-lg
    font-bold
    rounded-md 
    focus:outline-none 
    focus:border-none
    bg-transparent
  `}
  font-family: 'Poppins';
`;



    interface UserProps {
        cars: any;
        id: string;
        googleId: string;
        displayName: string;
        email: string;
        cnic: string;
        contactNumber: string;
        isVerified: boolean;
        photoLink: string;
    
    
    }
interface CarContainerProps {
    carId: string;
}
const CarContainer: React.FC<{ carId: string }> = ({ carId }) => {
 

    const { loading, error, data } = useQuery<GetCarById>(GET_CAR_BY_ID, {
        variables: { id: carId }
    });




    if (loading) return <p>Loading car...</p>;
    if (error) return <p>Error loading car: {error.message}</p>;
    if (!data || !data.car) return <p>Car not found</p>;

    // Transform the owner object if necessary
  

    return <CarMini id={data.car.id} name={data.car.name} monthlyPrice={data.car.monthlyPrice} dailyPrice={data.car.dailyPrice} hourlyPrice={data.car.hourlyPrice} carType={""} City={""} gearType={""} thumbnailUrl={""} photos={data.car.photos} year={0} color={""} features={""} isAvailable={false} owner={""} location={0} gas={""}  />;
};

const CarContainerMobile: React.FC<{ carId: string }> = ({ carId }) => {
  const { loading, error, data } = useQuery<GetCarById>(GET_CAR_BY_ID, {
      variables: { id: carId }
  });

  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const navigate = useNavigate();
  const [isFavorited, setIsFavorited] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
const isSmallMobile = useMediaQuery({ query: '(max-width: 430px)' })

  if (loading) return <p>Loading car...</p>;
  if (error) return <p>Error loading car: {error.message}</p>;
  if (!data || !data.car) return <p>Car not found</p>;


  // Transform the owner object if necessary
  const carProps: ICarProps = {
      ...data.car,
      owner: data.car.owner.displayName, // Use the appropriate field if needed
      location: data.car.location.coordinate, // Assuming location.coordinate is the number you want

  };
  
  // const handleCarClick = (data) => {
  //   navigate(`/car/${data.id}`, { state: { carProps: props } });
  // };
  const handleImageClick = (index: number, event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent the click from propagating to the outer div
    setLightboxOpen(true);
    setPhotoIndex(index);
  };
 

  return (
<>
  
  
  
          {data?.car?.photos.map((photo, index) => (
            <>   
            <div key={index}   style={{width:"100%",marginTop:".6rem",position:"relative"}}>
    
              <img  src={photo}  height={239} style={{width:"100%",borderRadius:"5px"}} alt={`Car ${index}`} onClick={(event) => handleImageClick(index, event)} />
             
              <div style={{display:'flex',flexDirection:"column",alignItems:"center",marginTop:"-1.5rem"}}> 
              <div style={{backgroundColor:"#1F4590",color:"#fff",fontSize:"20",paddingInline:"20px",paddingBlock:"10px",borderRadius:"8px"}}>PKR {data?.car?.dailyPrice}/ Day</div>
              </div>
              <div style={{position:"absolute",top:"2px",right:"10px"}}>   
              <CarDropdown carId={data.car.id}/>
              </div>
            </div>
            </>

          
          ))}
        
     
        </>

      
  );


};



const UserProfile: React.FC<{ userId: string }> = ({ userId }) => {
    const { loading, error, data,refetch } = useQuery(GET_USER_PROFILE, {
        variables: { id: userId }
    });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const isSmallMobile = useMediaQuery({ query: '(max-width: 430px)' })
    
    const [cnic, setCnic] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [googleId, setGoogleId] = useState("");
    const [photoFile, setPhotoFile] = useState<File | null>(null);
    const [isVerified, setIsVerified] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [editPhoto, setEditPhoto] = useState(false);
    const [photo, setPhoto] = useState("");
    const [User, setUser] = useState<UserProps | null>(null); // Define the user state
    const [updateUser] = useMutation(CREATE_USER_MUTATION);



    useEffect(() => {
      if (data && data.user) {
        setUser(data.user); // Set the user state if data.user exists
      }
    }, [data]);
  

    useEffect(() => {
      if (User) {
        setGoogleId(User.googleId);
        setIsVerified(User.isVerified);
        setDisplayName(User.displayName);
        setEmail(User.email);
        setCnic(User.cnic);
        setContactNumber(User.contactNumber);
        setPhoto(User.photoLink);
      }
     
    }, [User]);
  


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!User) return <p>User not found</p>; // Use the user state

    
console.log("data",data)
  
    const myhandleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
  
      const upUser = {
        googleId: googleId,
        photoLink: photoFile ? URL.createObjectURL(photoFile) : photo,
        isVerified: isVerified,
        displayName: displayName,
        email: email,
        cnic: cnic,
        contactNumber: contactNumber,
      };
  
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id: User.id, // Use User.id instead of user.id
            user: upUser,
          },
        })
        .then((res) => {

          setUser(res.data.updateUser);
       toast.success('Profile updated successfully');
      setEditPhoto(!editPhoto);

        })
        .catch((err) => {
          console.log(err);
          console.log(JSON.stringify(err, null, 2));
        });
    };
  
    const toggleEditPhoto = () => {
      setEditPhoto(!editPhoto);
    };
  
    const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        setPhotoFile(file);
        setPhoto(URL.createObjectURL(file));
        
      }
    };
  

    
    const CustomPrevArrow = (props: any) => {
      const { onClick } = props;
      return (
        <div
          style={{
            backgroundColor: "#1F4590",
            color: "white",
            borderRadius:"40px",
            width: "40px",
          
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: isMobile ? "40%":isSmallMobile?"12%":'45%', // Adjust the position as needed
            bottom: "-60px", // Adjust the position as needed
            zIndex: 10, // Ensure it's above the slider
          }}
          onClick={onClick}
        >
          <BsArrowLeft size={isSmallMobile?20:30} />
        </div>
      );
    };
  
    const CustomNextArrow = (props: any) => {
      const { onClick } = props;
      return (
        <div
          style={{
            backgroundColor: "#1F4590",
            color: "white",
            borderRadius:"40px",
            width: "40px",
          
            height:"40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: isSmallMobile?"35%":"45%", // Adjust the position as needed
            bottom: "-60px", // Adjust the position as needed
          
            zIndex: 10, // Ensure it's above the sliderW
          }}
          onClick={onClick}
        >
          <BsArrowRight size={isSmallMobile?20:30}  />
        </div>
      );
    };
  

    
 
    return (
        
        <Container>
           {
                isMobile

                ?
                <h1 style={{textAlign:"center",fontWeight:"bold",color:"#1F4590"}}> User Profile</h1>
:
          
             
                
                <h1 style={{textAlign:"center",fontWeight:"bold",color:"#1F4590"}}> User Profile</h1>
        
              }
{ isMobile ?
<>
<div className="Profile_container">
  <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>

                <img src={User.photoLink} alt="User Profile" style={{width:"132px",height:"132px",borderRadius:"132px"}} />

  </div>
  <h1 style={{marginBlock:".4rem",textAlign:"center"}}> {User.displayName}</h1>
<div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:'.7rem'}} >
  {
    !editPhoto ?
    (
      <>   
<MdOutlineModeEditOutline  onClick={toggleEditPhoto} />
<p>Edit Profile</p>

</>
    )
    :

<p> Profile</p>


}
</div>

<div style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:'.7rem'}} >
<p style={{fontSize:"16px",color:"#000",fontWeight:'600'}}>About</p>

<p style={{textDecoration:"underline"}}>Edit Profile</p>
</div>
{/* Info container User */}
<div style={{border:"1px solid #B1B3B4",borderRadius:"10px",padding:"10px"}}>
  {/* row */}

  <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",}} >
   
  <p> Name :</p>   

  <Input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)}    readOnly={!editPhoto} />



</div>
<hr />




<div style={{display:"flex",alignItems:"center",gap:'1rem',justifyContent:"space-between"}} >
  <p > Email Address :</p>
  <Input type="text" value={email} onChange={(e) => setEmail(e.target.value)} readOnly={!editPhoto} />



</div>

</div>



<div style={{border:"1px solid #B1B3B4",borderRadius:"10px",padding:"15px",marginBlock:"1rem"}}>
  {/* row */}

  <div style={{display:"flex",alignItems:"center",gap:'1rem',justifyContent:"space-between"}} >
  <p > Cnic :</p>
  <Input type="text" value={cnic} onChange={(e) => setCnic(e.target.value)} readOnly={!editPhoto} />




</div>






</div>

<div style={{border:"1px solid #B1B3B4",borderRadius:"10px",padding:"15px"}}>
  {/* row */}

  <div style={{display:"flex",alignItems:"center",gap:'1rem',justifyContent:"space-between"}} >
  <p > Contact Number :</p>
  <Input type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} readOnly={!editPhoto} />





</div>






</div>


<div style={{border:"1px solid #B1B3B4",borderRadius:"10px",padding:"15px",marginTop:"10px"}}>
  {/* row */}

 
 
  <p style={{fontSize:"16px",color:"#000",fontWeight:'600'}}>  Verified: {User.isVerified ? 'Yes' : 'No'} </p>


</div>





{/* Logout */}

<div style={{border:"1px solid #B1B3B4",borderRadius:"10px",padding:"15px",marginTop:"10px"}}>
  {/* row */}

 
 
  <p style={{fontSize:"16px",color:"#000",fontWeight:'600'}}>Logout</p>


</div>
{/* Deleter Account Button */}
<div style={{border:"1px solid #B1B3B4",borderRadius:"10px",padding:"15px",marginTop:"10px"}}>
  {/* row */}

 
 
  <p style={{fontSize:"16px",color:"#f11900",fontWeight:'600'}}>Delete Account</p>


</div>


<div style={{display:"flex",justifyContent:"center",alignItems:"center",marginBlock:"1rem"}}>   

<button onClick={myhandleSubmit} style={{backgroundColor:"#21408E",borderRadius:"5px",color:"#fff",border:"none",marginBottom:".6rem",paddingInline:"25px",paddingBlock:"8px",fontSize:"16px",fontFamily:"poppins"}}>Update </button>
</div>


{/* My car Section */}

<div style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:'.7rem',marginTop:"3rem",paddingInline:"20px"}} >
<p style={{fontSize:"16px",color:"#000",fontWeight:'600'}}>My Cars</p>

<p style={{textDecoration:"underline"}}>Edit</p>
</div>

<div style={{width:"100%"}}>
<Slider
              infinite={true}
              variableWidth={true}
              speed={300}
              slidesToShow={1}
              slidesToScroll={1}
              prevArrow={<CustomPrevArrow />} // Use your custom prev arrow component
              nextArrow={<CustomNextArrow />} // Use your custom next arrow component>
              responsive={[
                {
                  breakpoint: 430,
                  settings: {
                    slidesToShow: 1,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                  },
                },
               
              ]}
              >  
{User?.cars && User?.cars?.map((car: { id: string; }, index: React.Key | null | undefined) => (
                    <CarContainerMobile key={index} carId={car.id} /> // Use car.id instead of car
                ))}
</Slider>
                </div>

</div>

</>
:

            <ProfileContainer>
                <BoxProfileContainer>

               
              
                {editPhoto ? (
            <div>
              <img src={photo} alt="User" className="mt-4" style={{width:"132px",height:"132px",borderRadius:"132px"}} />
              <Input type="file" onChange={handlePhotoChange} accept="image/*" />
            </div>
          ) : (
            <div>
              <img src={photo} alt="User" className="mt-4" style={{width:"132px",height:"132px",borderRadius:"132px"}} />
              <button  type="button" onClick={toggleEditPhoto}>
              <MdOutlineModeEditOutline   />

              </button>
            </div>
          )}
                </BoxProfileContainer>
                <BoxinfoContainer>
               <div className="div">  
                  <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>

               <p> Name :   </p>   
                
                <Input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)}    readOnly={!editPhoto} />

                    
                </div>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>

<p> Email Address:   </p>
                    

                    <Input type="text" value={email} onChange={(e) => setEmail(e.target.value)} readOnly={!editPhoto} />

                    </div>  

                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>

                   <p>  CNIC: </p>   
                    
                    <Input type="text" value={cnic} onChange={(e) => setCnic(e.target.value)} readOnly={!editPhoto} />
                    </div>

                    <div style={{display:"flex",gap:"1rem",justifyContent:"space-between",alignItems:"center"}}>

                    <p> Contact Number:  </p>
          <Input type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} readOnly={!editPhoto} />
</div>
<div style={{display:"flex",gap:"1rem",alignItems:"center"}}>

                    <p>  Verified:    </p>

                    <p>{User.isVerified ? 'Yes' : 'No'}</p>

                    </div>
                    </div>
                </BoxinfoContainer>

    <button onClick={myhandleSubmit} style={{backgroundColor:"#21408E",borderRadius:"5px",color:"#fff",border:"none",marginBottom:".6rem",paddingInline:"25px",paddingBlock:"8px",fontSize:"16px",marginTop:"1rem",fontFamily:"poppins"}}>  Update  </button>



            </ProfileContainer>
}
{ !isMobile && 
            <CarsContainer>
                    <h1 style={{textAlign:"center"}}>My Cars</h1>

                <div style={{display:"flex",justifyContent:"center",gap:".8rem",alignItems:"center",flexWrap:"wrap"}}>


                {User?.cars && User?.cars?.map((car: { id: string; }, index: React.Key | null | undefined) => (
    <CarContainer key={index} carId={car.id} /> // Use car.id instead of car
))}

        </div >
            </CarsContainer>
       
                }
        </Container>
    );
};

export default UserProfile;
