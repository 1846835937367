import React from "react"
import tw from "twin.macro";
import styled from "styled-components";
import { ChatState } from "../../../Context/ChatProvider";
import { getSender,getSenderFull } from "../../../config/ChatLogics";
import { useEffect, useState } from "react";
import axios from "axios";
import io from "socket.io-client";
import toast from "react-hot-toast";
import MyChat from "../../components/MyChat/MyChat";
var socket, selectedChatCompare;
const Container = styled.div`
  ${tw`container mx-auto `}
`;
const StyledContainer = styled.div`
  ${tw`py-6  h-screen `}
`;

const StyledFlexContainer = styled.div`
  ${tw`flex border border-gray-600 rounded shadow-lg h-full`}
`;

const StyledLeftSection = styled.div`
  ${tw`w-1/3 border flex flex-col`}
`;

const StyledHeader = styled.div`
  ${tw`py-2 px-3 pt-12 bg-gray-300 flex flex-row justify-between items-center`}
`;

const StyledSearch = styled.div`
  ${tw`py-2 px-2 bg-gray-100 w-full`}
`;

const StyledInput = styled.input`
  ${tw` px-2 py-2 text-sm w-full`}
`;

const StyledContact = styled.div`
  ${tw`bg-gray-300 flex-1 overflow-auto`}
`;

const StyledContactItem = styled.div`
  ${tw`bg-white px-3 flex items-center hover:bg-gray-300 cursor-pointer`}
`;

const StyledImage = styled.img`
  ${tw`h-12 w-12 rounded-full`}
`;

const StyledMessageSection = styled.div`
  ${tw`ml-4 flex-1 border-b border-gray-100 py-4`}
`;

const StyledMessageText = styled.p`
  ${tw`text-gray-500 mt-1 text-sm`}
`;

const StyledTimestamp = styled.div`
  ${tw`text-xs text-gray-700`}
`;



const StyledRightSection = styled.div`
  ${tw`w-2/3 border flex flex-col`}
`;


const StyledContentContainer = styled.div`
  ${tw`flex-1 overflow-auto`}
`;

const StyledDateIndicator = styled.div`
  ${tw`rounded py-2 px-4 bg-blue-200 mb-2 flex justify-center`}
`;

const StyledEncryptionNotification = styled.div`
  ${tw`rounded py-2 px-4 bg-yellow-200 mb-4 flex justify-center`}
`;

const StyledMessage = styled.div`
  ${tw`flex mb-2`}
`;

const StyledMessageBubble = styled.div`
  ${tw`rounded-md py-2 px-3`}
`;




const StyledInputContainer = styled.div`
  ${tw`bg-gray-100 px-4 py-4 flex items-center gap-8`}
`;





export default function Chat  () {

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const { selectedChat, setSelectedChat, user, notification, setNotification } =
  ChatState();


  const fetchMessages = async () => {
    if (!selectedChat) return;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      setLoading(true);

      const { data } = await axios.get(
        `/api/message/${selectedChat._id}`,
        config
      );
      setMessages(data);
      setLoading(false);

      socket.emit("join chat", selectedChat._id);
    } catch (error) {
      // toast({
      //   title: "Error Occured!",
      //   description: "Failed to Load the Messages",
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "bottom",
      // });
    }
  };

  const sendMessage = async (event) => {
    if (event.key === "Enter" && newMessage) {
      socket.emit("stop typing", selectedChat._id);
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };
        setNewMessage("");
        const { data } = await axios.post(
          "/api/message",
          {
            content: newMessage,
            chatId: selectedChat,
          },
          config
        );
        socket.emit("new message", data);
        setMessages([...messages, data]);
      } catch (error) {
        // toast({
        //   title: "Error Occured!",
        //   description: "Failed to send the Message",
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        //   position: "bottom",
        // });
      }
    }
  };

  // useEffect(() => {
  //   socket = io(ENDPOINT);
  //   socket.emit("setup", user);
  //   socket.on("connected", () => setSocketConnected(true));
  //   socket.on("typing", () => setIsTyping(true));
  //   socket.on("stop typing", () => setIsTyping(false));

  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   fetchMessages();

  //   selectedChatCompare = selectedChat;
  //   // eslint-disable-next-line
  // }, [selectedChat]);

  // useEffect(() => {
  //   socket.on("message recieved", (newMessageRecieved) => {
  //     if (
  //       !selectedChatCompare || // if chat is not selected or doesn't match current chat
  //       selectedChatCompare._id !== newMessageRecieved.chat._id
  //     ) {
  //       if (!notification.includes(newMessageRecieved)) {
  //         setNotification([newMessageRecieved, ...notification]);
  //         // setFetchAgain(!fetchAgain);
  //       }
  //     } else {
  //       setMessages([...messages, newMessageRecieved]);
  //     }
  //   });
  // });

  const typingHandler = (e) => {
    setNewMessage(e.target.value);

    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      socket.emit("typing", selectedChat._id);
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        socket.emit("stop typing", selectedChat._id);
        setTyping(false);
      }
    }, timerLength);
  };
    return(
    
       
        <Container >
          <StyledContainer >


            <StyledFlexContainer >
              {/* Left */}
              <StyledLeftSection>
          <MyChat/>
         
        </StyledLeftSection>


              {/* Right */}
              <StyledRightSection>
              {selectedChat ? (
                <>   

      {/* Header */}
      <StyledHeader style={{backgroundColor:"#1F4590"}}>
        <div style={{display:"flex",alignItems:"center"}}>
          <div>
            <img
              style={{width:"60px",height:"60px ",borderRadius:"50%"}}
              src="https://darrenjameseeley.files.wordpress.com/2014/09/expendables3.jpeg"
              alt="Movie Poster"
            />
          </div>
          <div style={{marginLeft:"8px"}}>
            <p style={{color:"white"}}>New Movie! Expendables 4</p>
            <p style={{color:"white"}}>Andrés, Tom, Harrison, Arnold, Sylvester</p>
          </div>
        </div>
        <div className="flex">
          {/* Icons */}
        </div>
      </StyledHeader>
      {/* Messages */}
      <StyledContentContainer style={{ backgroundColor: "#DAD3CC" }}>
        <div style={{paddingInline:"6px",paddingBlock:"10px"}}>
          <StyledDateIndicator>
            <p className="text-sm uppercase">February 20, 2018</p>
          </StyledDateIndicator>
          <StyledEncryptionNotification>
            <p className="text-xs">
              Messages to this chat and calls are now secured with end-to-end encryption. Tap for more info.
            </p>
          </StyledEncryptionNotification>
          <StyledMessage>

            <StyledMessageBubble style={{ backgroundColor: "#F2F2F2" }}>
              <div >Sylvester Stallone</div>
              <div >Hi everyone! Glad you could join! I am making a new movie.</div>
              <StyledTimestamp>12:45 pm</StyledTimestamp>
            </StyledMessageBubble>


          </StyledMessage>

          <StyledMessage>
           <StyledMessageBubble style={{ backgroundColor: "#F2F2F2" }}>
              <div >Sylvester Stallone</div>
              <div >Hi everyone! Glad you could join! I am making a new movie.</div>
              <StyledTimestamp>12:45 pm</StyledTimestamp>
            </StyledMessageBubble>
          </StyledMessage>
          <StyledMessage>
            <StyledMessageBubble style={{ backgroundColor: "#F2F2F2" }}>
            <div >Sylvester Stallone</div>
              <div >Hi everyone! Glad you could join! I am making a new movie.</div>
              <StyledTimestamp>12:45 pm</StyledTimestamp>
            </StyledMessageBubble>
          </StyledMessage>
          <StyledMessage>
            <StyledMessageBubble style={{ backgroundColor: "#F2F2F2" }}>
            <div >Sylvester Stallone</div>
              <div >Hi everyone! Glad you could join! I am making a new movie.</div>
              <StyledTimestamp>12:45 pm</StyledTimestamp>
            </StyledMessageBubble>
          </StyledMessage>
          {/* More message bubbles go here */}
        </div>
      </StyledContentContainer>
      {/* Input */}
      <StyledInputContainer>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
            <path
              opacity=".45"
              fill="#263238"
              d="M9.153 11.603c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962zm-3.204 1.362c-.026-.307-.131 5.218 6.063 5.551 6.066-.25 6.066-5.551 6.066-5.551-6.078 1.416-12.129 0-12.129 0zm11.363 1.108s-.669 1.959-5.051 1.959c-3.505 0-5.388-1.164-5.607-1.959 0 0 5.912 1.055 10.658 0zM11.804 1.011C5.609 1.011.978 6.033.978 12.228s4.826 10.761 11.021 10.761S23.02 18.423 23.02 12.228c.001-6.195-5.021-11.217-11.216-11.217zM12 21.354c-5.273 0-9.381-3.886-9.381-9.159s3.942-9.548 9.215-9.548 9.548 4.275 9.548 9.548c-.001 5.272-4.109 9.159-9.382 9.159zm3.108-9.751c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962z"
            />
          </svg>
        </div>
        <div style={{flex:"1"}}>
          <StyledInput type="text" />
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
            <path
              fill="#263238"
              fillOpacity=".45"
              d="M11.999 14.942c2.001 0 3.531-1.53 3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531S8.469 2.35 8.469 4.35v7.061c0 2.001 1.53 3.531 3.53 3.531zm6.238-3.53c0 3.531-2.942 6.002-6.237 6.002s-6.237-2.471-6.237-6.002H3.761c0 4.001 3.178 7.297 7.061 7.885v3.884h2.354v-3.884c3.884-.588 7.061-3.884 7.061-7.885h-2z"
            />
          </svg>
        </div>
      </StyledInputContainer>

      </> ):(
        <div style={{display:"flex",justifyContent:'center',alignItems:"center"}}>   
 <h5>   Click on a user to start chatting  </h5>
    
 </div>
      )
      }

    </StyledRightSection>


            </StyledFlexContainer>


          </StyledContainer>
        </Container>
     
      
      
    )
}