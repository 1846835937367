import  { Key, useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Car } from "../../components/car";
import carService from "../../services/carService";
import { Dispatch } from "redux";
import { GetCars_cars } from "../../services/carService/__generated__/GetCars";
import { setTopCars } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { makeSelectTopCars } from "./../../containers/HomePage/selectors";
import MoonLoader from "react-spinners/MoonLoader";
import CarDisplay from "../../components/CarDisplay";
import CarMini from "../../components/CarMini";
import { useMediaQuery } from "react-responsive";
const TopCarsContainer = styled.div`
  ${tw`
  py-20
    flex
    flex-wrap
    justify-center

    
  `};
`;

const CarsContainer = styled.div`
  ${tw`
    flex
    flex-wrap
    grid
    grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4
    gap-4
    mx-0
    ml-0
  `};

  gap: 2px;

  & > * {
    margin: 0.2rem;
    padding:0px;
  }
`;

const LoadingContainer = styled.div`
  ${tw`
    flex
    justify-center
    items-center
    text-base
    text-black
  `};
`;

const actionDispatch = (dispatch: Dispatch) => ({
  setTopCars: (cars: GetCars_cars[]) => dispatch(setTopCars(cars)),
});

const stateSelector = createSelector(makeSelectTopCars, (topCars) => ({
  topCars,
}));

const wait = (timeout: number) => new Promise((rs) => setTimeout(rs, timeout));

export function TopCars() {
  const [isLoading, setLoading] = useState(false);

  const { topCars } = useSelector(stateSelector);
  const { setTopCars } = actionDispatch(useDispatch());
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
const isSmallMobile = useMediaQuery({ query: '(max-width: 425px)' })
  console.log("Cars", topCars);

  const fetchTopCars = async () => {
    setLoading(true);
    const cars = await carService.getCars().catch((err) => {
      console.log("Error: ", err);
    });

    console.log("Cars: ", cars);
    if (cars) {
      // Shuffle the cars randomly
      const shuffledCars = [...cars].sort(() => Math.random() - 0.5);
      setTopCars(shuffledCars);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTopCars();
  }, []);

  const isEmptyTopCars = !topCars || topCars.length === 0;

  return (
    <TopCarsContainer>
      {isLoading && (
        <LoadingContainer>
          <MoonLoader />
        </LoadingContainer>
      )}
      {!isEmptyTopCars && !isLoading && (
        <CarsContainer>
          {topCars.map((car: { id: Key | null | undefined; name: string; thumbnailUrl: string; dailyPrice: number; monthlyPrice: number; hourlyPrice: number; carType: string; year: number; City: string; gearType: string; color: string; photos: string[]; }) => (
            <CarMini key={car.id} name={car.name} thumbnailUrl={car.thumbnailUrl} dailyPrice={car.dailyPrice} monthlyPrice={car.monthlyPrice} carType={car.carType} year={car.year} City={car.City} id={car.id} gearType={car.gearType} photos={car.photos} color={car.color} features={""} isAvailable={false} owner={""} location={0} hourlyPrice={car.hourlyPrice} gas={""} />
          ))}
        </CarsContainer>
      )}
    </TopCarsContainer>
  );
}

export default TopCars;
