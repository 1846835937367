import React, { useState } from "react";
import Slider from "react-slick";
import "./CarMini.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ICarProps } from "../car";
import reviewstars from './../../../assets/img/reviewstars.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartEmpty } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartFilled } from '@fortawesome/free-solid-svg-icons';
import CarDropdown from "../CarEditDropdown/Index";



interface Props {
  className: any;
}


export function CarMini(props: ICarProps) {
  const {
    name,id, monthlyPrice, dailyPrice, hourlyPrice, City, gearType, carType, thumbnailUrl, photos, year, color, features, isAvailable, owner, location
  } = props;
  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const navigate = useNavigate();
  const [isFavorited, setIsFavorited] = useState(false);

  const handleCarClick = () => {
    navigate(`/car/${props.id}`, { state: { carProps: props } });
  };
  const handleImageClick = (index: number, event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent the click from propagating to the outer div
    setLightboxOpen(true);
    setPhotoIndex(index);
  };


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 8000, // Speed of auto sliding (in milliseconds)
    arrows: false // Disable back and forward arrows
  };

  const HeartFilledIcon = () => <svg> {/* filled heart SVG code here */} </svg>;
  const HeartEmptyIcon = () => <svg> {/* empty heart SVG code here */} </svg>;

  const handleFavoriteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent the click from propagating to the outer div
    setIsFavorited(!isFavorited);
    // TODO: Call API to update favorite status in the database
  };
  
  return (

    <div className={`car-mini `}>
      <div className="overlap-group-3">
        <Slider {...settings}>
          {photos.map((photo, index) => (
            <div key={index} className="image-container">
              <img className="CAR" src={photo} alt={`Car ${index}`} onClick={(event) => handleImageClick(index, event)} />
              <button className={`favorite-button ${isFavorited ? 'favorited' : ''}`} onClick={(event) => { handleFavoriteClick(event); }}>
                {isFavorited ? <FontAwesomeIcon icon={faHeartFilled} /> : <FontAwesomeIcon icon={faHeartEmpty} />}
              </button>
            </div>

          ))}
        </Slider>
        {isLightboxOpen && (
          <Lightbox
            mainSrc={photos[photoIndex]}
            nextSrc={photos[(photoIndex + 1) % photos.length]}
            prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
            onCloseRequest={() => setLightboxOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
          />
        )}
        <div onClick={handleCarClick} className={`car-mini`}> {/* Added onClick here */}

          <div className="rectangle" />
          <div className="Name">{name}</div>
        
          <div className="rectangle-2" />

          <div className="rs">PKR {dailyPrice}/ Day</div>
          <img className="reviewstars" alt="Reviewstars" src={reviewstars} />
        
        </div>
        <div style={{position:"absolute",top:"2px",right:"10px"}}>   
              <CarDropdown carId={`${id}`} />
              </div>
      </div>
    </div>
  );
};
export default CarMini;