import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import audi from "./../../../assets/img/audi.png";
import bmw from "./../../../assets/img/bmw.png";
import honda from "./../../../assets/img/honda.png";
import isuzu from "./../../../assets/img/isuzu.png";
import kia from "./../../../assets/img/kia.png";
import mercedes from "./../../../assets/img/mercedes.png";
import suzuki from "./../../../assets/img/suzuki.png";
import mg from "./../../../assets/img/mg.png";
import toyota from "./../../../assets/img/toyota.png";

import tw from "twin.macro";


const CarouselHeading = styled.h2`
  ${tw`
    text-left
    mt-10 // No top margin to attach it to the top of its container
    mb-16 // No bottom margin to control the height
    pl-2 // No padding-left to attach it to the left border
  `}
  font-family:'Copperplate', 'Poppins', sans-serif;
  font-weight: 400; // Increased weight for better visibility
  font-size: 1.25rem; // Increased font-size for visibility but maintaining the height requirement
  color: white;
  background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
  width: 40%;
  height: 2rem; // Set the height to 4rem
  line-height: 2rem; // Ensure the text is vertically centered in the 4rem height
  overflow: hidden; // Ensures the content does not exceed the specified height
  align-items: center; // Centers the text vertically
  justify-content: space-around; // Aligns the text to the start (left)
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 50%; // Allow the heading to be more responsive on smaller screens
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 40%; // Adjust width for medium screens
  }

  @media (min-width: 1025px) {
    width: 30%; // Fixed width for larger screens
  }
`;




const BrandBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
align-items:center;
`;

const BrandButton = styled(Link)`
  width: 40%; /* Adjust width for responsiveness */
  margin: 10px; /* Adjust spacing between buttons */
  max-width: 100px; /* Limit maximum width */
`;


const BrandImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  transition: transform 0.3s ease; /* Add transition effect for smooth hover */
  
  &:hover {
    transform: scale(1.05); /* Scale up slightly on hover */
  }
`;
const brands = [
  {
    name: "audi",
    imgSrc: audi,
    alt: "audi",
  },
  {
    name: "bmw",
    imgSrc: bmw,
    alt: "bmw",
  },
  {
    name: "honda",
    imgSrc: honda,
    alt: "honda",
  },
  {
    name: "isuzu",
    imgSrc: isuzu,
    alt: "isuzu",
  },
  {
    name: "kia",
    imgSrc: kia,
    alt: "kia",
  },
  {
    name: "mercedes",
    imgSrc: mercedes,
    alt: "mercedes",
  },
  // {
  //   name: "faislabad",
  //   imgSrc: faislabad,
  //   alt: "faislabad",
  // },
  {
    name: "mg",
    imgSrc: mg,
    alt: "mg",
  },
  // {
  //   name: "gujranwala",
  //   imgSrc: gujranwala,
  //   alt: "gujranwala",
  // },
  {
    name: "suzuki",
    imgSrc: suzuki,
    alt: "suzuki",
  },
  {
    name: "toyota",
    imgSrc: toyota,
    alt: "toyota",
  }, 
];

const Bybrand = () => {
  return (
    <><CarouselHeading>browse by Make</CarouselHeading>
    <BrandBox>
      {brands.map((brand, index) => (
        <BrandButton key={index} to={`/cars/${brand.name}`}>
          <BrandImage src={brand.imgSrc} alt={brand.alt} />
        </BrandButton>
      ))}
    </BrandBox></>
  );
};

export default Bybrand;
